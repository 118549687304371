import { makeStyles } from '@material-ui/core';
import Input, { InputProps } from '@material-ui/core/Input';
import React, { SyntheticEvent } from 'react';

import Colors from 'src/nightingale/Colors';

/**
 * Extend props from Material UI
 * @see {@link https://material-ui.com/api/input/#props}
 */
export interface TextInputProps
  extends Pick<
    InputProps,
    | 'autoComplete'
    | 'autoFocus'
    | 'disabled'
    | 'error'
    | 'id'
    | 'inputComponent'
    | 'inputProps'
    | 'inputRef'
    | 'multiline'
    | 'name'
    | 'onBlur'
    | 'onFocus'
    | 'placeholder'
    | 'readOnly'
    | 'required'
    | 'rows'
    | 'rowsMax'
    | 'type'
    | 'value'
    | 'startAdornment'
  > {
  onChange?: (value: string) => void;
}

/**
 * Styles
 */
const useStyles = makeStyles({
  root: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '145%',

    'label + & ': {
      marginTop: 0,
    },

    '& ::placeholder': {
      color: Colors.Gray4,
      fontStyle: 'italic',
    },
  },
  underline: {
    '&.MuiInput-underline::before': {
      borderColor: Colors.Gray2,
    },
    '&.MuiInput-underline::after': {
      borderColor: Colors.BlueSpruce,
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled)::before': {
      borderBottom: `1px solid ${Colors.BlueSpruce}`,
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled)::after': {
      borderBottom: `2px solid ${Colors.BlueSpruce}`,
    },
  },
  error: {
    '&.MuiInput-underline::after': {
      borderColor: Colors.Coral,
    },
  },
  multiline: {
    padding: '6px 0 7px',
  },
  inputMultiline: {
    minHeight: 23,
  },
  disabled: {
    backgroundColor: Colors.Gray1,
    cursor: 'not-allowed',
    color: Colors.Gray6,

    '$underline&::before': {
      borderColor: Colors.Gray3,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
  },
});

/**
 * This is a simple wrapper around Material UI's `Input`, meant to be usable
 * by multiple controls.
 * @see {@link https://material-ui.com/api/input/}
 */
export const TextInput: React.FC<TextInputProps> = ({ inputProps, onChange, ...rest }) => {
  const styles = useStyles();

  const handleChange = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!onChange) return;
    const { value } = event.currentTarget;
    onChange(value);
  };

  return (
    <Input
      classes={{
        disabled: styles.disabled,
        error: styles.error,
        inputMultiline: styles.inputMultiline,
        multiline: styles.multiline,
        root: styles.root,
        underline: styles.underline,
      }}
      fullWidth
      autoComplete="off"
      inputProps={{ 'data-testid': 'text-input', ...inputProps }}
      onChange={handleChange}
      rowsMax={10}
      {...rest}
    />
  );
};
