/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import '@fontsource/material-icons';
import '@fontsource/nunito/400-italic.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500-italic.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/600-italic.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700-italic.css';
import '@fontsource/nunito/700.css';
import '@fontsource/tenor-sans/400.css';
import MuiCssBaseline from '@mui/material/CssBaseline';
import MuiGlobalStyles from '@mui/material/GlobalStyles';
import React from 'react';

import { Colors } from 'src/common/ui/base/Colors';
import { TypographyStyles } from 'src/common/ui/base/Typography';

const StaticGlobalStyles = (
  <>
    <MuiCssBaseline />
    <MuiGlobalStyles
      styles={{
        'html, body': css`
          ${TypographyStyles.body1}
          background-color: ${Colors.CoolGray1};
          color: ${Colors.CoolGray9};
        `,
        '*, *::after, *::before': css`
          box-sizing: border-box;
        `,
        '.material-icons': css`
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 24px;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          -webkit-font-feature-settings: 'liga';
          -webkit-font-smoothing: antialiased;
        `,
        button: css`
          ${TypographyStyles.body2}
        `,
      }}
    />
  </>
);

export const GlobalStyles: React.FC = () => StaticGlobalStyles;
