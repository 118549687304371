/**
 * Typography styles for commonly used font levels.
 */
import { css } from '@emotion/react';

import { Colors } from 'src/common/ui/base/Colors';
import { Spacing } from 'src/common/ui/base/Spacing';

/**
 * @file Typography
 * The primary source of truth for these colors is Figma.
 *
 * @src {@link https://figma.com/design/J2hlcm5TFWuXmbu7Sqi9pA/?node-id=13-10&t=l2wqus48K5WonT8b-0}
 */

/**
 * Font stack for our top line header font, Tenor Sans.
 */
const TenorSansFontStack = `"Tenor Sans", "Nunito", "Nunito Sans", "Open Sans", "Roboto", ui-sans-serif, system-ui, sans-serif`;

/**
 * Font stack for our main body font, Nunito Sans.
 */
export const NunitoSansFontStack = `"Nunito", "Nunito Sans", "Open Sans", "Roboto", ui-sans-serif, system-ui, sans-serif`;

export const TypographyStyles = {
  header1: css`
    color: ${Colors.CoolGray9};
    font-family: ${TenorSansFontStack};
    font-size: 26px;
    font-weight: 400;
    line-height: 37.7px;
    margin-top: 0;
    margin-bottom: ${Spacing.Unit4}px;
    text-transform: capitalize;
  `,
  subheader1: css`
    color: ${Colors.CoolGray9};
    font-family: ${NunitoSansFontStack};
    font-size: 16px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: ${Spacing.Unit2}px;
    line-height: 1.45em;
  `,
  subheader2: css`
    color: ${Colors.CoolGray9};
    font-family: ${NunitoSansFontStack};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.12em;
    line-height: 1.45em;
    margin-top: 0;
    margin-bottom: ${Spacing.Unit2}px;
    text-transform: uppercase;
  `,
  subheader3: css`
    color: ${Colors.CoolGray9};
    font-family: ${NunitoSansFontStack};
    font-size: 12px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: ${Spacing.Unit2}px;
    line-height: 1.45em;
  `,
  body1: css`
    color: ${Colors.CoolGray9};
    font-family: ${NunitoSansFontStack};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.45em;
  `,
  body2: css`
    color: ${Colors.CoolGray9};
    font-family: ${NunitoSansFontStack};
    font-size: 13px;
    font-weight: 400;
    line-height: 1.45em;
  `,
  body3: css`
    color: ${Colors.CoolGray9};
    font-family: ${NunitoSansFontStack};
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 1.45em;
  `,
  buttonText1: css`
    font-family: ${NunitoSansFontStack};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.12em;
    line-height: 1.45;
    text-transform: uppercase;
  `,
};
