import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AccessTime from '@material-ui/icons/AccessTime';
import { differenceInYears, format, parseISO } from 'date-fns';
import React from 'react';

import { VOICEMAIL_CONSENT_OPTIONS } from 'src/components/forms/schemas/definitions';
import PhoneNumber from 'src/components/general/phoneNumber';
import { MenuPatient } from 'src/generated/gql/graphql';
import Colors from 'src/nightingale/Colors';
import { getFullName } from 'src/patientHeader/domain/getFullName';

export const PatientIdentity = ({ patient }: { patient: MenuPatient }) => {
  const classes = useStyles();
  if (!patient) return <div />;
  let voicemailConsentLabel = VOICEMAIL_CONSENT_OPTIONS.unknown;

  if (patient?.consentsToVoicemails === true) {
    voicemailConsentLabel = VOICEMAIL_CONSENT_OPTIONS.granted;
  } else if (patient?.consentsToVoicemails === false) {
    voicemailConsentLabel = VOICEMAIL_CONSENT_OPTIONS.not_granted;
  }
  return (
    <div className={classes.container}>
      <Typography className={classes.infoHeader} title="Preferred Name">
        {getFullName(patient)}
        {patient.isOnPayorWaitlist && (
          <span>
            {' '}
            <AccessTime fontSize="small" />
          </span>
        )}
        {patient.pronouns && <small title="Pronouns">{` (${patient.pronouns})`}</small>}
      </Typography>
      {patient.dob && (
        <Typography component="div" className={classes.infoSubHeader} title="Date of Birth, Age">
          {format(parseISO(patient.dob), 'MM/dd/yyyy')}{' '}
          {`(Age ${differenceInYears(new Date(), parseISO(patient.dob))})`}
        </Typography>
      )}
      {patient.phone && (
        <Typography component="div" className={classes.infoSubHeader} title="Phone and Voicemail">
          <PhoneNumber number={patient.phone} />
          <em className={classes.voiceMails}>Voicemails: {voicemailConsentLabel}</em>
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    minWidth: 220,
  },
  infoHeader: {
    color: Colors.Gray8,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 18,
    fontWeight: 600,
    marginTop: 10,
    letterSpacing: 0.2,

    '&:first-child': {
      marginTop: 0,
    },

    '& small': {
      fontSize: 12,
    },
  },
  infoSubHeader: {
    color: Colors.Gray8,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.2,
  },
  voiceMails: {
    marginLeft: '8px',
  },
});
