import { Button, makeStyles } from '@material-ui/core';
import { IconBrandHipchat } from '@tabler/icons-react';
import addMinutes from 'date-fns/addMinutes';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';
import React from 'react';

import useTypedSWR from 'src/components/general/useTypedSWR';
import { MainFocusArea } from 'src/myDayToday/MainFocusArea';
import { ScheduleWidget } from 'src/myDayToday/ScheduleWidget';
import { TopBar } from 'src/myDayToday/TopBar';
import { generatePopInEvent } from 'src/myDayToday/generatePopInEvent';
import { getProviderId } from 'src/myDayToday/getProviderId';
import { useInterval } from 'src/myDayToday/hooks/useInterval';
import { GET_NEXT_EVENT_CARD_QUERY } from 'src/myDayToday/queries.gql';
import { BoulderColors } from 'src/util/brand';

/**
 * The rate at which the MDT content should be refreshed
 */
const AUTO_REFRESH_TIME_MS = 30 * 1000;

export const MyDayToday = () => {
  const styles = useStyles();
  const providerId = getProviderId();
  const [currentTime, setCurrentTime] = React.useState(new Date());
  useInterval(() => setCurrentTime(new Date()), AUTO_REFRESH_TIME_MS);
  const start = roundToNearestMinutes(currentTime, { roundingMethod: 'floor' });
  const end = addMinutes(start, 10);

  const { data, error } = useTypedSWR([
    GET_NEXT_EVENT_CARD_QUERY,
    {
      searchWindow: { start: start.toISOString(), end: end.toISOString() },
      providerId,
    },
  ]);

  const nextEventIsValid = data && !error;
  const popinEvent = nextEventIsValid ? generatePopInEvent(data.staff_getNextEventCard) : null;

  if (!providerId) {
    return <div className={styles.container}>No provider ID found</div>;
  }

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <TopBar currentTime={currentTime} />
        <div className={styles.bottom}>
          <ScheduleWidget
            currentTime={currentTime}
            providerId={providerId}
            popinEvent={popinEvent}
          />
          <MainFocusArea currentTime={currentTime} providerId={providerId} />
        </div>
      </div>
      <Button
        className="feedbackButton"
        onClick={() => {
          window.open('https://forms.gle/JfazgwjYPiDDdFQi8', '_blank');
        }}
      >
        <IconBrandHipchat fill="white" />
        Give Feedback
      </Button>
    </main>
  );
};

const useStyles = makeStyles({
  main: {
    height: 'calc(100vh - 64px)',
    backgroundColor: BoulderColors.Gray1,
    overflow: 'auto',

    '& .feedbackButton': {
      position: 'fixed',
      bottom: 0,
      right: 46,
      padding: '8px 11px 8px 8px',
      textTransform: 'uppercase',
      background: 'linear-gradient(112deg, #98BECE -15.14%, #F47070 105.47%)',

      color: 'white',
      fontFamily: '"Nunito", "Nunito Sans"',
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '142%',
      letterSpacing: 1.44,

      '& svg': {
        marginRight: 6,
      },
    },
  },
  container: {
    boxSizing: 'border-box',
    fontFamily: '"Nunito", "Nunito Sans"',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    padding: 24,
    gap: 51,
  },
});
