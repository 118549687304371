import { Button, MenuItem, Select, TextField } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { PlaylistAdd } from '@material-ui/icons';
import React, { useContext, useState } from 'react';

import FeatureFlag from 'src/components/featureflags/featureFlag';
import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import Snackbar from 'src/components/general/Snackbar';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import {
  PAYOR_BASED_WAITLIST_PROMOTION,
  PROMOTE_WAITLISTED_PATIENTS,
} from 'src/featureFlags/currentFlags';
import Colors from 'src/nightingale/Colors';
import { InlineHelp } from 'src/nightingale/components/ChartElement/InlineHelp';
import logger from 'src/shared/util/logger';
import { useWaitlistPayors } from 'src/waitlist/useWaitlistPayors';
import { WAITLIST_PROMOTE_MUTATION } from 'src/waitlist/waitlistPromote.gql';

const ALL_PAYORS = 'All payors';
const PROMOTION_HELP_TEXT = `
 Promoting prospective patients off the waitlist will trigger the following actions:
  <ul>
    <li>A task asking prospective patients to opt into continuing their Boulder enrollment</li>
    <li>An SMS text message telling prospective patients they have a new communication waiting for them in the Boulder App</li>
  </ul>
  A maximum of 100 prospective patients can be promoted.
`;

const UnauthorizedView = () => <div>Unauthorized - You do not have access to this page</div>;

const WaitlistPromotionPage: React.FC = () => {
  const classes = useStyles();
  const [promotionCountInput, setPromotionCountInput] = useState<string>('1');
  const [selectedPayorId, setSelectedPayorId] = useState<string>(ALL_PAYORS);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { apolloClient } = useContext(ApolloClientContext);

  const { waitlistPayors, refetch: refetchPayors } = useWaitlistPayors();

  const handlePromotion = async () => {
    if (!apolloClient) {
      setError(true);
      return;
    }

    setIsLoading(true);

    try {
      const promotionCount = parseInt(promotionCountInput, 10);
      if (!Number.isInteger(promotionCount)) {
        throw new Error('Invalid value for number of patients to promote');
      }

      await apolloClient.mutate({
        mutation: WAITLIST_PROMOTE_MUTATION,
        variables: {
          count: promotionCount,
          payorId: selectedPayorId === ALL_PAYORS ? null : selectedPayorId,
        },
      });
      refetchPayors();
      setSuccess(true);
    } catch (err) {
      logger.error(`Error promoting prospective patients: ${err}`);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const hasAccessToWaitlistPromotion = useContext(FeatureFlagContext)[PROMOTE_WAITLISTED_PATIENTS];
  if (!hasAccessToWaitlistPromotion) {
    return <UnauthorizedView />;
  }

  const integersOneToOneHundredRegex = /^[1-9][0-9]?$|^100$/;
  const isValidPromotionCountInput =
    integersOneToOneHundredRegex.test(promotionCountInput) &&
    Number.isInteger(parseInt(promotionCountInput, 10));

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          {error && (
            <Snackbar
              message="Error promoting prospective patients"
              color="error"
              onDismiss={() => {
                setError(false);
              }}
            />
          )}
          {success && (
            <Snackbar
              message={`Successfully promoted ${promotionCountInput} prospective patients off the waitlist`}
              onDismiss={() => {
                setSuccess(false);
              }}
            />
          )}
          <section className={classes.section}>
            <span className={classes.titleText}>Waitlist Promotion</span>

            <Card className={classes.cardContainer}>
              <section className={classes.section}>
                <CardHeader
                  title="Promote prospective patients off the waitlist"
                  classes={{ title: classes.header, root: classes.cardHeaderContainer }}
                />
                <InlineHelp helpText={PROMOTION_HELP_TEXT} />
              </section>
              <TextField
                type="number"
                inputProps={{ min: '1', max: '100' }}
                onChange={e => {
                  setPromotionCountInput(e.target.value);
                }}
                value={promotionCountInput}
                error={!isValidPromotionCountInput}
                disabled={isLoading || success || error}
                data-testid="promotion-count-input"
              />
              <FeatureFlag name={PAYOR_BASED_WAITLIST_PROMOTION}>
                <Select
                  data-testid="payor-select"
                  style={{ maxWidth: 500, marginLeft: 10 }}
                  value={selectedPayorId}
                  onChange={e => {
                    setSelectedPayorId(e.target.value as string);
                  }}
                >
                  <MenuItem key={ALL_PAYORS} value={ALL_PAYORS}>
                    {ALL_PAYORS}
                  </MenuItem>
                  {waitlistPayors.map(payor => (
                    <MenuItem key={payor.payorId} value={payor.payorId}>
                      {payor.payorName} ({payor.count})
                    </MenuItem>
                  ))}
                </Select>
              </FeatureFlag>
              <CardContent className={classes.cardContentContainer}>
                <Button
                  className={classes.iconButton}
                  onClick={handlePromotion}
                  startIcon={<PlaylistAdd />}
                  disabled={!isValidPromotionCountInput || isLoading || success || error}
                  data-testid="promotion-count-button"
                >
                  Promote
                </Button>
              </CardContent>
            </Card>
          </section>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: 600,
  },
  header: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '17.4px',
    '&, &.Mui-error, &.Mui-focused': {
      color: Colors.BlueSpruce,
    },
    transform: 'scale(1)',
    position: 'static',
  },
  cardContainer: {
    backgroundColor: Colors.White,
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: 30,
    overflow: 'visible',
    padding: 16,
  },
  cardHeaderContainer: {
    padding: 0,
  },
  cardContentContainer: {
    padding: 0,
    position: 'relative',
    '&:last-child': { paddingBottom: 0 },
  },
  section: {
    marginBottom: 20,
  },
  titleText: {
    fontFamily: 'Tenor Sans',
    fontSize: 26,
    lineHeight: '145%',
    color: Colors.Gray6,
    textTransform: 'capitalize',
    height: '50px',
    marginBottom: '30px',
  },
  root: {
    backgroundColor: Colors.ChartGray,
    padding: theme.spacing(3),
    height: '100%',
    minHeight: '100vh',
  },
  iconButton: {
    alignItems: 'center',
    backgroundColor: Colors.Stillwater,
    border: 'none',
    borderRadius: 0,
    color: Colors.White,
    cursor: 'pointer',
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    margin: '16px 16px 0 0',
    padding: '8px 16px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
  },
}));

export default WaitlistPromotionPage;
