import { makeStyles } from '@material-ui/core';
import React from 'react';

import Colors from 'src/nightingale/Colors';

const HelpText: React.FC<{
  text: string;
}> = ({ text }) => {
  const styles = useStyles();
  return (
    <div className={styles.helpText} data-testid="list-help-text">
      {text}
    </div>
  );
};

const useStyles = makeStyles({
  helpText: {
    color: Colors.Gray6,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 13,
    fontStyle: 'italic',
    lineHeight: '17.4px',
  },
});

export default HelpText;
