import { createTheme } from '@mui/material';

import { Colors } from 'src/common/ui/base/Colors';
import { Spacing } from 'src/common/ui/base/Spacing';
import { NunitoSansFontStack, TypographyStyles } from 'src/common/ui/base/Typography';

export const Theme = createTheme({
  palette: {
    primary: {
      main: Colors.Blue4,
      dark: Colors.Blue6,
      light: Colors.Blue3,
      contrastText: Colors.White,
    },
    secondary: {
      main: Colors.Blue3,
      dark: Colors.Blue4,
      light: Colors.Blue2,
      contrastText: Colors.White,
    },
    success: {
      main: Colors.Green2,
      dark: Colors.Green3,
      light: Colors.Green1,
      contrastText: Colors.White,
    },
    error: {
      main: Colors.Red2,
      dark: Colors.Red3,
      light: Colors.Red1,
      contrastText: Colors.White,
    },
    info: {
      main: Colors.Gray4,
      dark: Colors.Gray6,
      light: Colors.Gray3,
      contrastText: Colors.White,
    },
    warning: {
      main: Colors.Peach4,
      dark: Colors.Peach5,
      light: Colors.Peach3,
      contrastText: Colors.White,
    },
  },
  typography: {
    fontFamily: NunitoSansFontStack,
    fontSize: 16,
    h1: TypographyStyles.header1,
    h2: TypographyStyles.header1,
    h3: TypographyStyles.subheader1,
    h4: TypographyStyles.subheader2,
    h5: TypographyStyles.subheader3,
    h6: TypographyStyles.subheader3,
    body1: TypographyStyles.body1,
    body2: TypographyStyles.body2,
    button: undefined,
    caption: undefined,
    overline: undefined,
  },
  spacing: [
    Spacing.Unit1,
    Spacing.Unit2,
    Spacing.Unit3,
    Spacing.Unit4,
    Spacing.Unit5,
    Spacing.Unit6,
  ],
});
