import { makeStyles, Theme } from '@material-ui/core/styles';
import { ApolloError } from 'apollo-client';
import { format, parseISO } from 'date-fns';
import { GraphQLError } from 'graphql';
import { inject } from 'mobx-react';
import React from 'react';

import ErrorAlert from 'src/components/general/ErrorAlert';
import UserFixableErrorAlert from 'src/components/general/UserFixableErrorAlert';
import useTypedSWR from 'src/components/general/useTypedSWR';
import { PageColumn } from 'src/nightingale/components/common/PageColumn/PageColumn';
import { PageContainer } from 'src/nightingale/components/common/PageContainer/PageContainer';
import {
  DOSE_SPOT_SSO_URL,
  DOSE_SPOT_PRESCRIBING_DATA_FOR_PATIENT,
} from 'src/prescribing/doseSpot/queries.gql';
import { colors } from 'src/util/colors';

interface ErxProps {
  patientId?: string;
  providerId?: string;
}

interface UserFixableError extends GraphQLError {
  exception: {
    cause: {
      originalMessage: string;
    };
  };
}

function isUserFixableError(error: any): error is UserFixableError {
  return !!error.exception?.cause?.originalMessage;
}

const UrlErrorAlert: React.VFC<{ error: ApolloError }> = ({ error }) => {
  if (error.graphQLErrors?.[0] && isUserFixableError(error.graphQLErrors[0])) {
    return (
      <UserFixableErrorAlert
        errorMessage={error.graphQLErrors[0].exception.cause.originalMessage}
      />
    );
  }

  return <ErrorAlert message="Error loading DoseSpot." error={error.message} />;
};

const Erx: React.VFC<ErxProps> = ({ patientId, providerId }) => {
  const { data: urlData, error: urlError } = useTypedSWR(
    providerId ? [DOSE_SPOT_SSO_URL, { patientId: patientId ?? null }] : null,
    {
      revalidateOnFocus: false,
    },
  );

  const { data: patientPrescribingData, error: patientPrescribingDataError } = useTypedSWR(
    patientId && urlData ? [DOSE_SPOT_PRESCRIBING_DATA_FOR_PATIENT, { patientId }] : null,
  );

  const isShowingIframe = !!(urlData && !urlError);
  const styles = useStyles({ hasPatientId: !!patientId });

  return (
    <PageContainer noPadding={isShowingIframe}>
      {!urlData && !urlError && !patientPrescribingData && !patientPrescribingDataError ? (
        <PageColumn>
          <p>Loading...</p>
        </PageColumn>
      ) : null}

      {urlError ? (
        <PageColumn>
          <UrlErrorAlert error={urlError} />
        </PageColumn>
      ) : null}

      {patientPrescribingDataError ? (
        <PageColumn>
          <ErrorAlert
            message="Error fetching DoseSpot patient prescribing data."
            error={patientPrescribingDataError.message}
          />
        </PageColumn>
      ) : null}

      {isShowingIframe ? (
        <>
          <div className={styles.iframeWrapper}>
            <iframe
              className={styles.iframe}
              data-testid="erx-iframe"
              height="100%"
              src={urlData?.doseSpotSsoUrl}
              title="DoseSpot"
              width="100%"
            />
          </div>

          {patientPrescribingData ? (
            <div className={styles.patientPrescribingDataContainer}>
              {patientPrescribingData.prescribingStateForPatient ? (
                <span>Prescribing state: {patientPrescribingData.prescribingStateForPatient}.</span>
              ) : null}
              {patientPrescribingData.lastSuccessfulSyncForPatient ? (
                <span>
                  Last successful sync:{' '}
                  {format(
                    parseISO(patientPrescribingData.lastSuccessfulSyncForPatient),
                    "PP 'at' h:mm:ss a",
                  )}
                </span>
              ) : null}
            </div>
          ) : null}
        </>
      ) : null}
    </PageContainer>
  );
};

const PATIENT_BAR_HEIGHT = 23;

const useStyles = makeStyles<Theme, { hasPatientId: boolean }>({
  iframeWrapper: {
    height: ({ hasPatientId }) =>
      `calc(100vh - 64px - ${hasPatientId ? `${PATIENT_BAR_HEIGHT}px` : '0px'})`,
  },
  iframe: {
    border: 'none',
    display: 'block',
  },
  patientPrescribingDataContainer: {
    borderTop: `1px solid ${colors.secondary}`,
    boxSizing: 'border-box',
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 12,
    height: PATIENT_BAR_HEIGHT,
    lineHeight: '13px',
    overflow: 'hidden',
    padding: '5px 20px',
    textAlign: 'right',

    '& span': {
      marginLeft: 10,
    },
  },
});

export default inject<
  { rootStore: { auth: { user: { id: string } } } },
  ErxProps,
  Record<string, string>,
  typeof Erx
>(stores => ({
  providerId: stores.rootStore.auth.user.id,
}))(Erx);
