import { IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import throttle from 'lodash/throttle';
import React, { SyntheticEvent, useState, useRef, useEffect, useCallback } from 'react';

import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles({
  wrapper: {
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuList: {
    backgroundColor: Colors.Ocean,
    pointerEvents: 'auto',
    padding: 0,

    '& li': {
      backgroundColor: Colors.Ocean,
      color: 'white',
      width: '173px',
      height: '37px',
      padding: '10px',
      fontFamily: '"Nunito", "Nunito Sans"',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '17.4px',

      '&:not(:first-child)': {
        borderTop: `1px solid rgba(255,255,255,0.15)`,
      },

      '&:hover': {
        backgroundColor: Colors.BlueSpruce,
      },

      '&.Mui-disabled': {
        backgroundColor: Colors.Gray7,
        color: Colors.Gray5,
        cursor: 'not-allowed',
        opacity: 1,
      },
    },
  },
  menuPaper: {
    borderRadius: 0,
  },
  popover: {
    pointerEvents: 'none',
  },
  iconButton: {
    padding: 0,
    top: -2,
    '& svg': {
      transform: 'scale(0.66)',
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
});

// check if mouse is hovering element
const checkBound = (event: MouseEvent, element: HTMLElement): boolean => {
  if (!element) return false;

  const rect = element.getBoundingClientRect();

  if (
    event.clientX < rect.left ||
    event.clientX > rect.right ||
    event.clientY < rect.top ||
    event.clientY > rect.bottom
  )
    return false;

  return true;
};

/**
 * Menu
 */
export const ChartPropertyMenu: React.FC<{
  allowNone: boolean;
  isEmpty: boolean;
  isEmptyValue: boolean;
  markAsNone: () => void;
  setShowHistoryModal: (doShowModal: boolean) => void;
  setShowNotes: (boolean) => void;
  showingNotes: boolean;
}> = ({
  allowNone,
  isEmpty,
  isEmptyValue,
  markAsNone,
  setShowHistoryModal,
  setShowNotes,
  showingNotes,
}) => {
  const styles = useStyles();

  const menuButtonRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
    window.removeEventListener('mousemove', handleMouseMove);
  };
  const handleOpen = (event: SyntheticEvent<HTMLButtonElement>) => {
    if (anchorEl) return;
    setAnchorEl(event.currentTarget);
    window.addEventListener('mousemove', handleMouseMove);
  };

  // listen to mouse movement for hover state
  const handleMouseMove = useCallback(
    // using mousemove event over onMouseLeave for more solid behavior
    throttle((event: MouseEvent) => {
      const hoverMenuButton = checkBound(event, menuButtonRef.current as HTMLElement);
      const hoverMenu = checkBound(event, menuRef.current as HTMLElement);

      if (!hoverMenuButton && !hoverMenu) handleClose();
    }, 100),
    [menuRef, menuButtonRef, anchorEl],
  );

  // stop listening when menu closed
  useEffect(() => {
    if (!anchorEl) {
      window.removeEventListener('mousemove', handleMouseMove);
    }
  }, [anchorEl]);

  // cleanup on component unmount
  useEffect(() => {
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const [showNotesDelay, setShowNotesDelay] = useState(showingNotes);

  return (
    <div className={styles.wrapper} ref={menuButtonRef} data-testid="chart-property-menu">
      <IconButton
        classes={{ root: styles.iconButton }}
        data-testid="chart-property-menu-toggle"
        onMouseEnter={handleOpen}
        onMouseMove={handleOpen}
        tabIndex={-1}
      >
        <MoreVertIcon className="three-dot-icon" />
      </IconButton>
      <Menu
        MenuListProps={{
          classes: { root: styles.menuList },
          ref: menuRef,
        }}
        PopoverClasses={{ root: styles.popover }}
        TransitionProps={{
          onExited: () => {
            // hide show notes button after menu closed
            if (showingNotes) setShowNotesDelay(true);
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoFocus={false}
        classes={{ paper: styles.menuPaper }}
        container={menuButtonRef.current}
        disableAutoFocusItem
        getContentAnchorEl={null}
        id="simple-menu"
        keepMounted={false}
        open={!!anchorEl}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem
          disabled={showNotesDelay}
          onClick={() => {
            handleClose();
            setShowNotes(true);
          }}
        >
          Show notes
        </MenuItem>

        {allowNone ? (
          <MenuItem
            disabled={!isEmptyValue}
            onClick={() => {
              handleClose();
              markAsNone();
            }}
          >
            {!isEmpty ? 'Mark as none' : 'Unset None'}
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            handleClose();
            setShowHistoryModal(true);
          }}
        >
          History
        </MenuItem>
      </Menu>
    </div>
  );
};
