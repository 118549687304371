/** @jsxImportSource @emotion/react */
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { THEME_ID } from '@mui/material/styles';
import React from 'react';

import { Theme } from 'src/common/ui/base/Theme/Theme';

export const ThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={{ [THEME_ID]: Theme }}>{children}</MuiThemeProvider>
);
