import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import { HighlightedText } from 'src/nightingale/components/ChartSearch/HighlightedText';

/**
 * Styles
 */
const useStyles = makeStyles<Theme, { inInteractionView: boolean }>({
  summary: {
    color: Colors.Black,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '23.2px',
    paddingRight: 30,
    paddingLeft: ({ inInteractionView }) => (inInteractionView ? 25 : 0),
    '& a': {
      color: Colors.Ocean,
    },
    '& > :first-child': {
      marginTop: 0,
    },
    '& > :last-child': {
      marginBottom: 0,
    },
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      margin: 0,
      fontSize: '1em',
      fontWeight: 'normal',
      lineHeight: 'inherit',
    },
    '& h1': {
      fontWeight: 'bold',
    },
    '& li': {
      marginBottom: 8,
    },
    '& ul': {
      paddingInlineStart: ({ inInteractionView }) => (inInteractionView ? 30 : 40),
      marginBlockStart: ({ inInteractionView }) => (inInteractionView ? 10 : 16),
    },
    '& p': {
      marginTop: 0,
      marginBottom: '11.6px',
    },
    '& .summarization__small, & small': {
      fontSize: 13,
      lineHeight: '17.4px',
    },
  },
  actionText: {
    fontWeight: 800,
    color: Colors.Gray5,
    textTransform: 'uppercase',
    letterSpacing: 1.6,
    fontSize: '13px',
  },
});

export const ChartElementSummary: React.FC<{
  actionText?: string | null;
  summarization: string;
  inInteractionView?: boolean;
}> = ({ actionText, summarization, inInteractionView = false }) => {
  const styles = useStyles({ inInteractionView });
  return (
    <div
      className={styles.summary}
      // ChartElementSummary should disallow focus from bubbling up, which
      // would trigger edit mode on the parent ChartElement.
      // Since `focus` doesn't bubble, the only way to catch the bubbling event
      // is by enabling focus on this `div` and then programatically disabling
      // it and stopping the event from bubbling up.
      onFocus={event => {
        event.preventDefault();
        event.stopPropagation();
      }}
      tabIndex={-1}
    >
      <div className={styles.actionText}>{actionText}</div>
      <HighlightedText text={summarization} />
    </div>
  );
};
