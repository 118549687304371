import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import type { LabelType } from 'src/nightingale/types/types';

export const useStyles = makeStyles<
  Theme,
  {
    labelType?: LabelType;
  }
>({
  header: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '17.4px',
    '&, &.Mui-error, &.Mui-focused': {
      color: ({ labelType }) =>
        labelType?.includes('interaction-header') ? Colors.Taupe : Colors.BlueSpruce,
    },
    transform: 'scale(1)',
    position: 'static',
  },
  label: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: '14.5px',
    letterSpacing: `.12em`,
    '&, &.Mui-error, &.Mui-focused': {
      color: Colors.Gray6,
    },
    textTransform: 'uppercase',
    transform: 'scale(1)',
    position: 'static',
  },
  requiredAsterisk: {
    color: Colors.Coral,
  },
  requiredErrorLabel: {
    background: Colors.Coral,
    color: Colors.White,
    paddingLeft: '2px',
    paddingRight: '2px',
    borderRadius: '3px',
  },
  helperText: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '145%',
    marginTop: 4,
    '&, &.Mui-error': {
      color: Colors.Coral,
    },
  },
});

/**
 * Wraps an Input component, such as the TextInput with a label and an optional
 * helper text or error message.
 */
export const LabeledFormControl: React.FC<{
  error?: boolean;
  errorMessage?: string;
  htmlFor?: string;
  hasEmptyValue?: boolean;
  isRequired?: boolean;
  label?: string;
}> = ({ children, error, errorMessage, htmlFor, hasEmptyValue, isRequired, label }) => {
  const styles = useStyles({});

  return (
    <FormControl
      data-testid="labeled-form-control"
      error={error}
      fullWidth
      aria-required={isRequired}
    >
      {label ? (
        <InputLabel
          classes={{
            root: styles.label,
          }}
          shrink
          htmlFor={htmlFor}
        >
          <span className={isRequired && hasEmptyValue ? styles.requiredErrorLabel : undefined}>
            {label}
          </span>
          {isRequired ? <span className={styles.requiredAsterisk}>*</span> : null}
        </InputLabel>
      ) : null}

      {children}

      {errorMessage && (
        <FormHelperText classes={{ error: styles.helperText }} error={error}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};
