import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import React, { useState } from 'react';

import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import Colors from 'src/nightingale/Colors';

export const BUTTON_LABEL = 'Send SMS To Patients';

export const testId = 'message-send-button';

interface MessageSendButtonProps {
  handleSendMessages: () => void;
  isDisabled?: boolean;
  message?: string;
  numPatients: number;
}

const MessageSendButton = ({
  handleSendMessages,
  isDisabled,
  message,
  numPatients,
}: MessageSendButtonProps) => {
  const styles = useStyles();
  const [confirmSendDialog, setConfirmSendDialog] = useState<boolean>(false);

  return (
    <>
      <Button
        data-testid={testId}
        onClick={() => setConfirmSendDialog(true)}
        className={styles.iconButton}
        startIcon={<PlaylistAdd />}
        disabled={isDisabled}
      >
        {BUTTON_LABEL}
      </Button>
      {confirmSendDialog && (
        <ConfirmDialog
          onSubmit={() => {
            handleSendMessages();
            setConfirmSendDialog(false);
          }}
          onCancel={() => setConfirmSendDialog(false)}
          submitLabel="Yes, Send Messages"
          cancelLabel="No"
        >
          {`You are about to send the following messsage to ${numPatients} patient(s):`}
          <span className={styles.message}>{message}</span>
        </ConfirmDialog>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  iconButton: {
    alignItems: 'center',
    backgroundColor: Colors.Stillwater,
    border: 'none',
    borderRadius: 0,
    color: Colors.White,
    cursor: 'pointer',
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    margin: '16px 16px 0 0',
    padding: '8px 16px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
  },
  message: {
    display: 'block',
    marginTop: 10,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    fontWeight: 600,
    fontStyle: 'italic',
  },
}));

export default MessageSendButton;
