import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';

import { RouterLinkDiv } from 'src/components/general/routerLink';
import Colors from 'src/nightingale/Colors';

const determineIconToDisplay = ({
  isActiveTab,
  ActiveIcon,
  InactiveIcon,
}: {
  isActiveTab: boolean;
  ActiveIcon?: React.ElementType;
  InactiveIcon?: React.ElementType;
}): JSX.Element | undefined => {
  if (!ActiveIcon && !InactiveIcon) {
    return undefined; // nothing to show
  }
  const classes = useTabStyles();

  if (isActiveTab && ActiveIcon) {
    return <ActiveIcon className={classNames(classes.tabIcon, classes.styleCustomIcon)} />;
  }

  if (InactiveIcon) {
    return <InactiveIcon className={classNames(classes.tabIcon, classes.styleCustomIcon)} />;
  }
  return undefined;
};

type PatientMenuTabProps = {
  /**
   * Used to identify this Tab
   * value and key required by material-ui Tabs component
   */
  value: string;
  /**
   * The currently active tab
   */
  activeTab: string;
  patientId: string;
  title: string;
  /**
   * Element or text displayed on tab
   */
  label: React.ReactNode;
  /**
   * do not show this menu tab when true
   */
  hide?: boolean;
  /**
   * No icon shown if not provided and inactive
   */
  InactiveIcon?: React.ElementType;
  /**
   * ActiveIcon ignored if url is provided
   */
  ActiveIcon?: React.ElementType;
  /**
   * url will open in new tab on click
   */
  url?: string;
  /**
   * Behavior and style treated as a subTab when provided
   */
  isSubTab?: boolean;
  /**
   * Supports query params for RouterLink
   */
  tabItem?: string;
  queryParams?:
    | {
        [key: string]: any;
      }
    | undefined;
};

const PatientMenuTab = ({
  value: tab,
  activeTab,
  patientId,
  title,
  hide,
  InactiveIcon,
  ActiveIcon,
  label,
  tabItem: subTab,
  isSubTab,
  url,
  queryParams,
}: PatientMenuTabProps): JSX.Element | null => {
  const classes = useTabStyles();
  if (hide) {
    return null;
  }

  // override value w/ tabItem if provided to support active state on nested tabs
  const tabValue = isSubTab ? subTab : tab;

  // checks activeTab against tab, or subTab if provided
  const isActiveTab = isSubTab ? activeTab === subTab : activeTab === tab;

  // style tab based on active state, if subTab provided style as subTab
  const className = isActiveTab
    ? classNames(isSubTab && classes.subTab, classes.tab, classes.selectedTab)
    : classNames(isSubTab && classes.subTab, classes.tab, classes.unselectedTab);

  // display icon based on where this tab is active
  const icon = determineIconToDisplay({ isActiveTab, ActiveIcon, InactiveIcon });

  // if url is provided, use the active tab to stay on the same tab when navigating
  const navTargetTab = url ? activeTab : tab;

  return (
    <Tab
      icon={icon}
      className={className}
      routeName="showPatient"
      label={label}
      title={title}
      value={tabValue}
      onClick={url ? () => window.open(url, '_blank') : undefined}
      // router link args https://nareshbhatia.github.io/mobx-state-router/docs/api-router-link
      component={RouterLinkDiv}
      params={{ id: patientId, tab: navTargetTab, ...(subTab && { tabItem: subTab }) }}
      queryParams={queryParams}
    />
  );
};

const LETTER_SPACING = 0.3;

const useTabStyles = makeStyles({
  tab: {
    minWidth: 138, // Override material UI default
    width: 138,
    padding: 0,
    minHeight: 50,
    letterSpacing: LETTER_SPACING * 3,
    fontSize: 13,
    lineHeight: '13px',
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 'bold',
    '& > *': {
      // mui overrides
      flexDirection: 'row',
      alignItems: 'inherit',
      justifyContent: 'flex-start',
      padding: 8,
    },
    opacity: 1,
  },
  subTab: {
    paddingLeft: 12,
    minHeight: 30,
    backgroundColor: Colors.Gray2,
    color: Colors.Gray6,
  },
  selectedTab: {
    backgroundColor: Colors.BoulderBlue,
    color: Colors.White,
  },
  unselectedTab: {
    color: Colors.Gray8,
  },
  tabIcon: {
    paddingRight: 8,
    paddingLeft: 8,
    marginBottom: '2px !important', // Override material UI default
  },
  styleCustomIcon: {
    color: 'inherit',
    '&:hover': {
      color: 'inherit', // Colors.BoulderBlue,
    },
    fontSize: 24,
  },
});

export default PatientMenuTab;
