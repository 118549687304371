import { Theme, makeStyles } from '@material-ui/core';

import Colors from 'src/nightingale/Colors';

export const useStyles = makeStyles<Theme, { disabled?: boolean }>({
  container: {
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : 'pointer'),
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginTop: 8,
  },
  listItem: {
    color: ({ disabled }) => (disabled ? Colors.Gray6 : Colors.Black),
    cursor: 'pointer',
    listStyle: 'disc',
    marginLeft: 28,
    paddingBottom: 8,
    paddingTop: 8,
    pointerEvents: ({ disabled }) => (disabled ? 'none' : 'auto'),

    '& ol:hover div': {
      display: 'block',
    },

    '&:focus': {
      backgroundColor: Colors.Gray1,
      outline: 'none',
    },

    '&:focus ol div': {
      display: 'block',
    },

    '&:first-child': {
      marginTop: 0,
    },
  },
  innerList: {
    marginInlineStart: 0,
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginLeft: 28,
    '& li:first-child': {
      fontWeight: 800,
    },
    position: 'relative',
  },
  innerListItem: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '23.2px',
    marginRight: 56,
  },
  editIcon: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'none',
  },
  listItemIcon: {
    '& svg': {
      color: Colors.Stillwater,
      height: 28,
      width: 28,
      '& path': {
        fill: Colors.Stillwater,
      },
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  iconButton: {
    marginTop: 10,
    marginLeft: -3, // visually align icon with list bullet
    '&:focus': {
      backgroundColor: Colors.Gray1,
    },
    '& svg': {
      color: Colors.Stillwater,
      height: 28,
      width: 28,
      '& path': {
        fill: Colors.BlueSpruce,
      },
    },
    '&:focus svg': {
      color: Colors.BlueSpruce,
      '& path': {
        fill: Colors.BlueSpruce,
      },
    },
    '&:hover svg': {
      color: Colors.BlueSpruce,
      '& path': {
        fill: Colors.BlueSpruce,
      },
    },
  },
  iconButtonDisabled: {
    '& svg path': {
      fill: Colors.Gray3,
    },
  },
  listItemIconDisabled: {
    '& svg path': {
      fill: Colors.Gray3,
    },
  },
  requiredAsterisk: {
    height: 13,
    left: -5,
    position: 'relative',
    top: -5,
    width: 13,
  },
});
