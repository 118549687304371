import { makeStyles } from '@material-ui/core';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import { HighlightedText } from 'src/nightingale/components/ChartSearch/HighlightedText';

/**
 * Styles
 */
const useStyles = makeStyles({
  inlineHelp: {
    color: Colors.Gray6,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 13,
    fontStyle: 'italic',
    lineHeight: '17.4px',
    marginTop: 10,
    marginBottom: 10,
    '& a': {
      color: Colors.Ocean,
    },
    '& > :first-child': {
      marginTop: 0,
    },
    '& > :last-child': {
      marginBottom: 0,
    },
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      margin: 0,
      fontSize: '1em',
      fontWeight: 'normal',
      lineHeight: 'inherit',
    },
    '& h1': {
      fontWeight: 'bold',
    },
    '& li': {
      marginBottom: 8,
    },
    '& ul': {
      paddingInlineStart: 30,
      marginBlockStart: 10,
    },
    '& p': {
      marginTop: 0,
      marginBottom: '11.6px',
    },
    '& .summarization__small, & small': {
      fontSize: 13,
      lineHeight: '17.4px',
    },
  },
});

export const InlineHelp: React.FC<{
  helpText: string;
}> = ({ helpText }) => {
  const styles = useStyles();
  return (
    <div className={styles.inlineHelp} data-testid="inline-help">
      <HighlightedText text={helpText} />
    </div>
  );
};
