import { makeStyles, Theme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LoopIcon from '@material-ui/icons/Loop';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import Colors from 'src/nightingale/Colors';
import { ChartContext } from 'src/nightingale/components/ChartContext/ChartContext';
import { ChartElementEditIcon } from 'src/nightingale/components/ChartElement/ChartElement.EditIcon';
import { ChartElementLabel } from 'src/nightingale/components/ChartElement/ChartElement.Label';
import { ChartElementSummary } from 'src/nightingale/components/ChartElement/ChartElement.Summary';
import { InlineHelp } from 'src/nightingale/components/ChartElement/InlineHelp';
import ChartElement from 'src/nightingale/components/ChartElement/domain/ChartElement';
import { usePrevious } from 'src/nightingale/hooks/usePrevious';
import useRequiredChartProperties from 'src/nightingale/requiredChartProperties/useRequiredChartProperties';
import { ChartElement as TChartElement } from 'src/nightingale/types/types';

const useErrorStyles = makeStyles({
  errorMessage: {
    color: Colors.Coral,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '11.6px',
    marginLeft: 10,
  },
  errorIcon: {
    marginLeft: 5,
    verticalAlign: 'middle',
    fill: Colors.Coral,
  },
});

const ChartElementViewError: React.FC = ({ children }) => {
  const styles = useErrorStyles();
  return (
    <span className={styles.errorMessage} data-testid="chart-element-view-save-error">
      {children}
      <ErrorOutlineIcon className={styles.errorIcon} />
    </span>
  );
};

export const getActionText = (element: ChartElement): string | null => {
  if (!element.hasResponse) {
    return null;
  } else if (element.isUpdated) {
    return 'Updated.';
  } else if (element.isConfirmed) {
    return 'Confirmed no change.';
  }
  return null;
};

const useStyles = makeStyles<Theme, { overlayCta: boolean; interactionReferenceId?: string }>({
  container: {
    backgroundColor: ({ overlayCta }) => (overlayCta ? Colors.Mist : undefined),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: ({ overlayCta }) => {
      if (overlayCta) {
        return 10;
      }
      return 12;
    },
    marginLeft: ({ overlayCta }) => (overlayCta ? 0 : 16),
    padding: ({ overlayCta }) => (overlayCta ? '12px 16px' : 0),
    position: 'relative',
    '&:hover svg': {
      opacity: 0.5,
    },
  },
  inner: {
    opacity: ({ overlayCta }) => (overlayCta ? 0.5 : 1),
  },
  refreshSnapshotButton: {
    background: 'none',
    border: 'none',
    bottom: 0,
    cursor: 'pointer',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
    '&:focus': {
      outline: `2px solid ${Colors.Stillwater}`,
    },
  },
  refreshSnapshotButtonIcon: {
    color: Colors.BlueSpruce,
    height: 19,
    position: 'absolute',
    right: 16,
    top: 16,
    width: 19,
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 4,
    pointerEvents: 'all',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  inlineHelp: {
    marginTop: -5,
    marginBottom: 5,
    paddingLeft: ({ interactionReferenceId }) => (interactionReferenceId ? 25 : 0),
  },
  loader: {
    color: Colors.BlueSpruce,
    verticalAlign: 'middle',
  },
  '@keyframes checkIconFadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  checkIcon: {
    animation: '$checkIconFadeOut 550ms ease-in 2500ms',
  },
  editingIconContainer: {
    height: 24,
  },
});

export const ChartElementView: React.FC<{
  definition: TChartElement;
  hasSaveError?: boolean;
  hasValidationError?: boolean;
  isSaving?: boolean;
  startEditing: () => void;
}> = ({ definition, hasSaveError, hasValidationError, isSaving, startEditing }) => {
  const chartContext = useContext(ChartContext);
  const { interactionReferenceId } = chartContext;
  const requiredChartProperties = useRequiredChartProperties();
  const flags = useContext(FeatureFlagContext);

  const element = new ChartElement(definition, chartContext, requiredChartProperties, flags);

  const styles = useStyles({
    overlayCta: !!element.onRefreshSnapshot,
    interactionReferenceId,
  });

  const { label, parsedInlineHelp, summarization } = definition;

  const [didJustSave, setDidJustSave] = useState(false);
  const previousIsSaving = usePrevious(isSaving);
  useEffect(() => {
    if (previousIsSaving && !isSaving) setDidJustSave(true);
    const timer = setTimeout(() => setDidJustSave(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [isSaving, previousIsSaving]);

  const showPencil =
    !isSaving && !didJustSave && !hasSaveError && !hasValidationError && !element.isReadOnly;
  const showCheckmark = !isSaving && didJustSave;

  return (
    <div className={styles.container} data-testid="chart-element-view">
      {element.onRefreshSnapshot ? (
        <button
          aria-label="Refresh snapshot"
          data-testid="chart-element-view-refresh-snapshot-button"
          className={styles.refreshSnapshotButton}
          onClick={element.onRefreshSnapshot}
          type="button"
        >
          <LoopIcon className={styles.refreshSnapshotButtonIcon} />
        </button>
      ) : null}
      <div className={styles.inner}>
        <div
          className={styles.titleContainer}
          data-testid="chart-element-view-header"
          onClick={startEditing}
        >
          <ChartElementLabel
            hasMissingRequiredFields={element.hasMissingRequiredProperties}
            labelType={element.labelType}
            label={label}
          />

          <nav className={styles.editingIconContainer}>
            {showPencil && <ChartElementEditIcon />}

            {isSaving && <CircularProgress className={styles.loader} size="1rem" />}

            {showCheckmark && <CheckIcon className={classNames(styles.loader, styles.checkIcon)} />}

            {hasSaveError && !isSaving ? (
              <ChartElementViewError>Unable to save</ChartElementViewError>
            ) : null}

            {hasValidationError && !isSaving ? (
              <ChartElementViewError>
                One or more items are invalid and cannot be saved.
              </ChartElementViewError>
            ) : null}
          </nav>
        </div>
        {parsedInlineHelp && (
          <div className={styles.inlineHelp}>
            <InlineHelp helpText={parsedInlineHelp} />
          </div>
        )}
        {summarization ? (
          <ChartElementSummary
            actionText={element.isPatientChartElementInFlow ? getActionText(element) : null}
            summarization={summarization}
            inInteractionView={!!interactionReferenceId}
          />
        ) : null}
      </div>
    </div>
  );
};
