import { makeStyles } from '@material-ui/core';
import React from 'react';

import useTypedSWR from 'src/components/general/useTypedSWR';
import Colors from 'src/nightingale/Colors';
import { BupeRunOut } from 'src/patientHeader/components/badges/BupeRunOut';
import { CopyPatientId } from 'src/patientHeader/components/badges/CopyPatientId';
import { DrugScreeningPeriodStatus } from 'src/patientHeader/components/badges/DrugScreeningPeriodStatus';
import { Fyi } from 'src/patientHeader/components/badges/Fyi';
import { IdVerificationStatus } from 'src/patientHeader/components/badges/IdVerificationStatus';
import { LastCompletedVisitDate } from 'src/patientHeader/components/badges/LastCompletedVisitDate';
import { PatientLocation } from 'src/patientHeader/components/badges/PatientLocation';
import { VisitCadence } from 'src/patientHeader/components/badges/VisitCadence';
import { LOAD_PATIENT_HEADER_PATIENT } from 'src/patientHeader/components/badges/queries/patientHeaderPatient.gql';

export const PatientHeaderButtons = ({
  patientId,
  activeTab,
}: {
  patientId: string;
  activeTab: string;
}) => {
  const { data } = useTypedSWR([LOAD_PATIENT_HEADER_PATIENT, { id: patientId }], {
    revalidateOnFocus: false,
  });
  const patient = data?.menuPatient ?? undefined;

  const classes = useStyles();

  if (!patient?.id) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <CopyPatientId patientId={patient.id} />
      <IdVerificationStatus patient={patient} activeTab={activeTab} />
      <PatientLocation patient={patient} />
      <Fyi patientId={patient.id} />
      <VisitCadence patientId={patient.id} />
      <BupeRunOut patientId={patient.id} />
      <DrugScreeningPeriodStatus patientId={patient.id} />
      <LastCompletedVisitDate patientId={patient.id} />
    </div>
  );
};

const useStyles = makeStyles({
  copyPatientID: {
    appearance: 'none',
    background: 'none',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    margin: 0,
    padding: 0,
    textDecoration: 'underline',

    '&:hover': {
      color: Colors.BlueSpruce,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    gap: 8,
    color: Colors.Gray7,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: 0.2,
    marginTop: 10,
  },
});
