import { makeStyles, IconButton, SvgIcon, Input } from '@material-ui/core';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import React from 'react';

import Colors from 'src/nightingale/Colors';
import { ReactComponent as NoIcon } from 'src/nightingale/assets/no.svg';
import { ReactComponent as YesIcon } from 'src/nightingale/assets/yes.svg';

interface BooleanInputProps {
  isSelected: boolean | null;
  onChange: (selection: boolean) => void;
}

export const BooleanInput: React.VFC<BooleanInputProps> = ({ isSelected, onChange }) => {
  const styles = useStyles({ isSelected });
  return (
    <Input
      color="primary"
      disableUnderline
      fullWidth
      margin="dense"
      classes={{ root: styles.input }}
      inputComponent={InnerInput}
      inputProps={{
        styles,
        text: getDisplayText(isSelected),
        onNo: () => onChange(false),
        onYes: () => onChange(true),
      }}
    />
  );
};

const getDisplayText = (isSelected: boolean | null): string => {
  if (isNil(isSelected)) return '';
  return isSelected ? 'Yes' : 'No';
};

const InnerInput: React.FC<{
  onNo: () => void;
  onYes: () => void;
  styles: ReturnType<typeof useStyles>;
  text: string;
}> = ({ onNo, onYes, styles, text }) => (
  <div className={styles.container}>
    <div data-testid="boolean-input">
      <IconButton
        data-testid="boolean-input-yes"
        onClick={onYes}
        classes={{ root: classNames(styles.button, styles.yesButton) }}
      >
        <SvgIcon component={YesIcon} viewBox="0.5 0 30 30" fontSize="large" />
      </IconButton>
      <IconButton
        data-testid="boolean-input-no"
        onClick={onNo}
        classes={{ root: classNames(styles.button, styles.noButton) }}
      >
        <SvgIcon component={NoIcon} viewBox="0 0 30 30" fontSize="large" />
      </IconButton>
    </div>
    <div>
      <span className={styles.iconText}>{text}</span>
    </div>
  </div>
);

interface StyleProps {
  isSelected: boolean | null;
}
const useStyles = makeStyles({
  container: {
    display: 'flex',
    marginTop: 5,
    alignItems: 'center',
  },
  iconText: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '145%',
    marginLeft: 16,
  },
  button: {
    height: 34,
    width: 34,
    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
    margin: 5,
  },
  yesButton: {
    backgroundColor: ({ isSelected }: StyleProps) =>
      isSelected === true ? Colors.BlueSpruce : Colors.Gray3,
    '&:focus': {
      outline: `2px solid ${Colors.Stillwater}`,
    },
  },
  noButton: {
    backgroundColor: ({ isSelected }: StyleProps) =>
      isSelected === false ? Colors.BlueSpruce : Colors.Gray3,
    '&:focus': {
      outline: `2px solid ${Colors.Stillwater}`,
    },
  },
  input: {
    marginTop: '0 !important',
  },
});
