import { makeStyles } from '@material-ui/styles';

import Colors from 'src/nightingale/Colors';
import { BoulderColors } from 'src/util/brand';
import { colors } from 'src/util/colors';

export const useScheduledEventCardStyles = makeStyles({
  container: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    width: '100%',
    border: `1px solid ${BoulderColors.Blue4}`,

    '& .header': {
      padding: '8px 12px',
      backgroundColor: BoulderColors.Blue4,
      color: 'white',
      fontSize: 14,
      display: 'flex',
      verticalAlign: 'middle',
      textTransform: 'uppercase',

      '& .time': {
        fontWeight: 900,
      },
      '& .infoIcon': {
        paddingLeft: 4,
        maxHeight: 18,
      },
    },

    '&.late': {
      borderColor: colors.muiRed,

      '& .header': {
        backgroundColor: colors.muiRed,
      },

      '& .body': {
        backgroundColor: Colors.NewLightRed,
      },

      '& .body .acceptButton': {
        backgroundColor: BoulderColors.Peach5,
      },
    },

    '&.imminent': {
      borderColor: colors.Blue4,

      '& .header': {
        backgroundColor: colors.Blue4,
      },

      '& .body': {
        backgroundColor: BoulderColors.Blue1,
      },

      '& .body .acceptButton': {
        backgroundColor: BoulderColors.Peach5,
      },
    },

    '&.soon': {
      '& .body .acceptButton': {
        backgroundColor: BoulderColors.Peach5,
      },
    },

    '& .body': {
      padding: 24,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'stretch',
      alignItems: 'flex-start',
      gap: 24,
    },

    '& .body .details': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch',
      alignItems: 'stretch',
      gap: 8,
      flexGrow: 1,
    },

    '& .body .patientName': {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '100%',
    },

    '& .body .visitTimeFrame': {
      color: Colors.Gray9,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '100%',
    },

    '& .body .nonPatientEventTitle': {
      color: BoulderColors.Blue6,
      fontSize: 24,
      fontWeight: 600,
    },

    '& .body .avatar': {
      flexGrow: 0,
    },

    '& .body .avatar img': {
      boxSizing: 'border-box',
      width: 150,
      height: 150,
      borderRadius: 152,
      backgroundColor: 'silver',
      marginBottom: 8,
      objectFit: 'cover',
      border: `1px solid ${BoulderColors.Gray6}`,
    },

    '& .body .visitType': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: BoulderColors.Gray2,
      textTransform: 'uppercase',
      fontSize: 20,
      alignSelf: 'flex-start',
      padding: '4px 10px',
      borderRadius: 12,
      letterSpacing: 0.201,
      lineHeight: '100%',

      '& svg': {
        marginRight: 6,
      },
    },

    '& .body .nonPatientEventType': {
      color: BoulderColors.Blue6,
      textTransform: 'uppercase',
      fontSize: 24,
      fontWeight: 600,
      alignSelf: 'flex-start',
      letterSpacing: 0.201,
      lineHeight: '100%',
    },

    '& .body .patientInformation': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'stretch',
      gap: 8,
    },

    '& .body .informationList': {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,

      '& span[data-dsp-status="regulatory pause"]': {
        color: '#CD1818',
      },
    },

    '& .body .acceptButton': {
      backgroundColor: BoulderColors.Blue3,
      color: 'white',
      paddingRight: 11,

      '& svg': {
        marginRight: 6,
      },
    },

    '& .body .actions': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      gap: 8,
    },
  },
});
