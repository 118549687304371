import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

import Colors from 'src/nightingale/Colors';

const useStyles = makeStyles({
  loader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    lineHeight: '23.2px',
    '& .MuiCircularProgress-root': {
      marginRight: 16,
    },
    '& .MuiCircularProgress-colorPrimary': {
      color: Colors.BlueSpruce,
    },
  },
});

export type ChartLoadingSpinnerProps = {
  message: string;
};

/**
 * Spinner to show when loading all or part of a chart
 */
const ChartLoadingSpinner: React.FC<ChartLoadingSpinnerProps> = ({ message }) => {
  const styles = useStyles();

  return (
    <div className={styles.loader}>
      <CircularProgress /> <span>{message}</span>
    </div>
  );
};

export default ChartLoadingSpinner;
