import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Autocomplete, {
  createFilterOptions,
  AutocompleteGetTagProps,
} from '@material-ui/lab/Autocomplete';
import type { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import debounce from 'lodash/debounce';
import React, { useCallback, useContext, useState, useEffect, useRef } from 'react';
import Highlighter from 'react-highlight-words';

import { ApolloClientContext } from 'src/data/ApolloClientContext';
import Colors from 'src/nightingale/Colors';
import { LabeledFormControl } from 'src/nightingale/components/ChartProperty/controls/LabeledFormControl/LabeledFormControl';
import { searchOptionsAPI } from 'src/nightingale/data/searchOptionsAPI';
import { useControlState } from 'src/nightingale/hooks/useControlState';
import { useDidUpdateEffect } from 'src/nightingale/hooks/useDidUpdateEffect';
import { useUnmountEffect } from 'src/nightingale/hooks/useUnmountEffect';
import {
  SelectAsyncChartProperty,
  ControlProps,
  SelectOption,
  Selection,
  SelectOptionsList,
  SelectStaticChartProperty,
  SELECT_OPTION_OTHER,
} from 'src/nightingale/types/types';

/**
 * Data model
 */
type SelectControlProps = { allowMultiple?: boolean };
export type SelectAsyncControlProps = ControlProps<SelectAsyncChartProperty> & SelectControlProps;
export type SelectStaticControlProps = ControlProps<SelectStaticChartProperty> & SelectControlProps;

/**
 * Utils
 */
const filter = createFilterOptions<SelectOption>({ trim: true });
const getOptionLabel = (option: SelectOption | string) =>
  typeof option === 'string' ? option : option?.vanity || option?.label || option?.value || '';
const createOtherLabel = (label: string) => `Other: ${label}`;
const createSelectOption = (label: string): SelectOption => ({
  label: createOtherLabel(label),
  otherValue: label,
  value: SELECT_OPTION_OTHER,
});
const removeVanityPlates = (option: SelectOption) => {
  const newOption = { ...option };
  delete newOption.vanity;
  return newOption;
};
const removeInvalidOptions = (options: SelectOptionsList) =>
  options.filter(option => option.value !== SELECT_OPTION_OTHER);

/**
 * Styles
 */
const useStyles = makeStyles({
  chip: {
    backgroundColor: Colors.Gray2,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    fontWeight: 400,
    height: 23,
    maxWidth: 480,
    margin: '5px 5px 5px 0',
    padding: '16px 0',
    lineHeight: '145%',
    '& .MuiChip-deleteIcon': {
      color: Colors.Gray5,
      height: 18,
      '&:hover, &:active': {
        color: Colors.Black,
      },
    },
    '&:focus': { backgroundColor: Colors.Gray3 },
  },
  paper: {
    'border-radius': 0,
  },
  input: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '145%',
    minHeight: (props: StyleProps = {}) => (props.allowMultiple ? 43 : 34),
    '& ::placeholder': {
      color: Colors.Gray4,
      fontStyle: 'italic',
    },
    '&.MuiInput-underline::before': {
      borderColor: Colors.Gray2,
    },
    '&.MuiInput-underline::after': {
      borderColor: Colors.BlueSpruce,
    },
    '&.MuiInputBase-root:hover:not(.Mui-disabled)::before': {
      borderBottom: `1px solid ${Colors.BlueSpruce}`,
    },
    '&.MuiInputBase-root:hover:not(.Mui-disabled)::after': {
      borderBottom: `2px solid ${Colors.BlueSpruce}`,
    },
    '&.MuiInputBase-root.Mui-disabled::before': {
      borderColor: Colors.Gray3,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    '&.MuiInputBase-root.Mui-disabled': {
      '&, & .MuiInputBase-input': {
        backgroundColor: Colors.Gray1,
        cursor: 'not-allowed',
        color: Colors.Gray6,
      },
    },
  },
  mark: {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
  options: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
  },
  clearIndicator: {
    fontSize: '18px',
  },
  noOptions: {
    fontStyle: 'italic',
    opacity: 0.38,
    color: 'black',
    fontFamily: '"Nunito", "Nunito Sans"',
  },
});

const SelectInputComponent: React.FC<{
  description?: string;
  hasEmptyValue?: boolean;
  isRequired?: boolean;
  label?: string;
  name: string;
  params: Partial<TextFieldProps>;
}> = ({ description, hasEmptyValue, isRequired, label, name, params }) => (
  <LabeledFormControl hasEmptyValue={hasEmptyValue} isRequired={isRequired} label={label}>
    <TextField
      {...(params as TextFieldProps)}
      inputProps={{
        ...params.inputProps,
        'data-testid': 'select-control-text-field-input',
      }}
      name={name}
      placeholder={description}
    />
  </LabeledFormControl>
);

const SelectOptionComponent: React.FC<{ inputValue: string; option: SelectOption }> = ({
  inputValue,
  option,
}) => {
  const styles = useStyles({});
  return option?.otherValue ? (
    <span style={option.otherValue === 'otherPlaceholder' ? { fontStyle: 'italic' } : {}}>
      {getOptionLabel(option)}
    </span>
  ) : (
    <Highlighter
      autoEscape
      highlightClassName={styles.mark}
      searchWords={[inputValue]}
      textToHighlight={option?.label}
    />
  );
};

const SelectTagGroupComponent: React.FC<{
  getTagProps: AutocompleteGetTagProps;
  values: SelectOptionsList;
}> = ({ getTagProps, values }) => {
  const styles = useStyles({});
  return (
    <>
      {values.map((option, index) => (
        <Chip
          classes={{ root: styles.chip }}
          data-testid="select-control-chip"
          label={getOptionLabel(option)}
          title={getOptionLabel(option)}
          deleteIcon={<CloseIcon />}
          {...getTagProps({ index })}
        />
      ))}
    </>
  );
};

export const getCustomOtherValue = (inputValue: string): string => {
  const isExistingOtherOption = inputValue.includes('Other: ');
  return isExistingOtherOption ? inputValue.split('Other: ')[1] : inputValue;
};

type StyleProps = { allowMultiple?: boolean };

/**
 * Select Control component. Supports single, multiple and semi-structured.
 * @see {@link https://v4.mui.com/api/autocomplete/}
 */
export const SelectControl: React.FC<
  Partial<SelectAsyncControlProps & SelectStaticControlProps> & { allowMultiple?: boolean }
> = ({
  autoFocus,
  allowMultiple,
  allowOther,
  description,
  disabled,
  hasEmptyValue,
  label,
  isRequired,
  name,
  onChangeValue,
  options = [],
  optionsSource,
  value,
}) => {
  const styleProps: StyleProps = { allowMultiple };
  const styles = useStyles(styleProps);

  const { apolloClient } = useContext(ApolloClientContext);

  const suggestionText = `Start typing to ${optionsSource ? 'load' : 'add'} options...`;

  const [internalValue, setInternalValue] = useControlState<Selection>(
    value || (allowMultiple ? [] : null),
  );

  const [open, setOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<SelectOptionsList>([]);
  const [searchTerm, setSearchTerm] = useState<string>();

  const searchOptions = (term: string, client: ApolloClient<NormalizedCacheObject>) =>
    optionsSource ? searchOptionsAPI(term, optionsSource, client) : Promise.resolve([]);

  // Track mounted state for async seach API calls
  const isMounted = useRef(true);
  useUnmountEffect(() => {
    isMounted.current = false;
  }, []);

  const setSearchResultsFromAPI = useCallback(
    debounce(async (term: string) => {
      const results = apolloClient ? await searchOptions(term, apolloClient) : [];

      if (isMounted.current) {
        if (
          internalValue &&
          'value' in internalValue &&
          !results.find(result => result.value === internalValue.value)
        ) {
          // make sure to always show the selected value in the dropdown
          results.unshift(internalValue);
        }
        setSearchResults(results);
        setIsSearching(false);
      }
    }, 500),
    [setSearchResults, setIsSearching],
  );

  useEffect(() => {
    if (!optionsSource) return;

    if (searchTerm) {
      setIsSearching(true);
      setSearchResultsFromAPI(searchTerm);
    } else {
      setSearchResults([]);
      setIsSearching(false);
    }
  }, [searchTerm, optionsSource]);

  useDidUpdateEffect(() => {
    if (onChangeValue) onChangeValue(internalValue);
  }, [internalValue]);

  const addOtherOption: (
    options: SelectOption[],
    state: FilterOptionsState<SelectOption>,
  ) => SelectOption[] = (currentOptions, params) => {
    const newOptions = [...currentOptions];

    // Create disabled option to make it clear that custom values are supported or that the user
    // can type to load async options. The noOptionsText prop is not compatible with freeSolo, so
    // we create our own here for async selects with allowOther true.
    if (allowOther && params.inputValue === '') {
      newOptions.push({
        label: suggestionText,
        value: SELECT_OPTION_OTHER,
        otherValue: 'otherPlaceholder',
      });
    }

    // Suggest creation of custom value
    const isExistingOption = newOptions.some(
      option => getOptionLabel(option).toLowerCase() === params.inputValue.trim().toLowerCase(),
    );
    if (allowOther && params.inputValue !== '' && !isExistingOption) {
      const otherValue = getCustomOtherValue(params.inputValue);
      newOptions.push({
        label: createOtherLabel(otherValue),
        otherValue,
        value: SELECT_OPTION_OTHER,
        vanity: `Other: "${otherValue}"`,
      });
    }

    return newOptions;
  };

  const filterOptions: (
    options: SelectOption[],
    state: FilterOptionsState<SelectOption>,
  ) => SelectOption[] = (currentOptions, params) => {
    if (optionsSource) {
      return isSearching ? currentOptions : addOtherOption(currentOptions, params);
    }
    return addOtherOption(filter(currentOptions, params), params);
  };

  const addSelection = (_event: React.ChangeEvent, newValue: string | string[]) => {
    // Bail out if none is selected
    if (!newValue) {
      setInternalValue(null);
      return;
    }

    let newSelection: Selection = null;

    // Format strings in arrays as `SelectOption`
    if (Array.isArray(newValue))
      newSelection = newValue.map(val => (typeof val === 'string' ? createSelectOption(val) : val));
    // Format single strings as a `SelectOption` in an array
    else if (typeof newValue === 'string') newSelection = createSelectOption(newValue);
    else newSelection = newValue;

    // Remove vanity plates
    newSelection = Array.isArray(newSelection)
      ? newSelection.map(removeVanityPlates)
      : removeVanityPlates(newSelection);

    // Set new internal value
    setInternalValue(newSelection);

    // Close dropdown if not multiple
    if (!allowMultiple) setOpen(false);
  };

  const setSearchInput = (_event: React.ChangeEvent, newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  return (
    <Autocomplete
      autoHighlight
      classes={{
        inputRoot: styles.input,
        paper: styles.paper,
        option: styles.options,
        clearIndicatorDirty: styles.clearIndicator,
        noOptions: styles.noOptions,
      }}
      clearOnBlur
      closeIcon={<CloseIcon fontSize="inherit" />}
      data-testid="select-control"
      disabled={disabled}
      filterOptions={filterOptions}
      filterSelectedOptions={allowMultiple}
      getOptionDisabled={(option: SelectOption) => option?.label === suggestionText}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option: SelectOption, selected: SelectOption) =>
        option.value === selected.value
      }
      id={`${name}-Autocomplete`}
      loading={isSearching}
      multiple={allowMultiple}
      noOptionsText={searchTerm ? 'No options' : suggestionText}
      onChange={addSelection}
      onInputChange={setSearchInput}
      onBlur={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      open={open}
      options={removeInvalidOptions((optionsSource ? searchResults : options) || [])}
      popupIcon={<KeyboardArrowDownIcon fontSize="inherit" />}
      renderInput={params => (
        <SelectInputComponent
          description={description}
          hasEmptyValue={hasEmptyValue}
          isRequired={isRequired}
          label={label}
          name={name || params.id}
          params={{
            ...params,
            inputProps: {
              ...params?.inputProps,
              autoFocus,
              onKeyPress: event => {
                switch (event.key) {
                  case 'Escape':
                    setOpen(false);
                    break;
                  case ' ':
                    if (!open) {
                      event.preventDefault();
                      setOpen(true);
                    }
                    break;
                  default:
                }
              },
            },
          }}
        />
      )}
      renderOption={(option: SelectOption, { inputValue }) => (
        <SelectOptionComponent option={option} inputValue={inputValue} />
      )}
      renderTags={(values: SelectOption[], getTagProps) => (
        <SelectTagGroupComponent getTagProps={getTagProps} values={values} />
      )}
      value={internalValue}
    />
  );
};
