export const BaseUnit = 8;

/**
 * Standard spacing values.
 */
export enum Spacing {
  Unit1 = BaseUnit / 2,
  Unit2 = BaseUnit,
  Unit3 = BaseUnit * 2,
  Unit4 = BaseUnit * 3,
  Unit5 = BaseUnit * 4,
  Unit6 = BaseUnit * 8,
}
