import { makeStyles } from '@material-ui/core';
import MuiIconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';

import { useDidUpdateEffect } from 'src/nightingale//hooks/useDidUpdateEffect';
import Colors from 'src/nightingale/Colors';

/**
 * Styles
 */
const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 47,
    background: Colors.White,
    margin: '0 0 45px 0',
    width: '100%',
  },
  searchIcon: {
    color: (props: StyleProps) => (props.hasSearched ? Colors.BlueSpruce : Colors.Gray5),
    height: 22,
    width: 22,
    margin: '17px 21px 17px 16px',
  },
  closeButton: {
    height: '100%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:hover svg': {
      color: Colors.BlueSpruce,
    },
  },
  closeIcon: {
    width: 20,
    height: 20,
    margin: '0 18px',
    color: Colors.Gray4,
  },
  input: {
    fontFamily: '"Nunito", "Nunito Sans"',
    '& ::placeholder': {
      fontStyle: 'italic',
    },
  },
});

type StyleProps = { hasSearched: boolean };

interface ChartSearchProps {
  onChange: (string) => void;
}

export const ChartSearch: React.FC<ChartSearchProps> = ({ onChange }) => {
  const [value, setValue] = useState('');
  const styleProps: StyleProps = { hasSearched: !!value };
  const styles = useStyles(styleProps);

  useDidUpdateEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div className={styles.container}>
      <SearchIcon classes={{ root: styles.searchIcon }} />
      <TextField
        autoFocus
        InputProps={{
          classes: { root: styles.input },
          disableUnderline: true,
          inputProps: { 'data-testid': 'chart-search-input' },
          value,
        }}
        fullWidth
        onChange={e => setValue(e.target.value)}
        placeholder="Search for exact words or phrases"
        variant="standard"
      />
      <MuiIconButton
        classes={{
          root: `${styles.closeButton}`,
        }}
        onClick={() => setValue('')}
      >
        <CloseIcon classes={{ root: styles.closeIcon }} data-testid="search-close-icon" />
      </MuiIconButton>
    </div>
  );
};
