import { makeStyles } from '@material-ui/core';
import { addMinutes, isWithinInterval } from 'date-fns';
import getMinutes from 'date-fns/getMinutes';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo } from 'react';

import { getEventToolTip, isSlotDuringEvent } from 'src/calendars';
import EventCalendar from 'src/calendars/eventCalendar';
import { getTimeDisplay } from 'src/events/getEventDisplayTitle';
import { SubType } from 'src/events/visitTypes';
import {
  EventTiming,
  useScheduledEventTiming,
} from 'src/myDayToday/components/useScheduledEventTiming';
import { MinimalCalendarEventData } from 'src/myDayToday/generatePopInEvent';
import Colors from 'src/nightingale/Colors';
import { getPreferredFullName } from 'src/shared/stores/resource';
import {
  AVAILABILITY_SUB_TYPE_ITEMS,
  BLOCKED_EVENT_SUB_TYPES,
  EVENT_COLORS,
  labelForEventType,
} from 'src/shared/util/events';
import { EventInstance } from 'src/stores/models/event';
import useRootStore from 'src/stores/useRootStore';
import { BoulderColors } from 'src/util/brand';
import { colors as calendarColors, getCalendarEventStyles } from 'src/util/calendar';
import { localZone } from 'src/util/timezones';

export const getEventDisplayTitle = (event: EventInstance, timezone: string) => {
  const eventLabel = labelForEventType(event.type, event.subType);
  const timeDisplay = getTimeDisplay(event, timezone);

  const firstPatient = event.attendees.find(
    attendee => attendee.__typename === 'Patient' || attendee.__typename === 'MyDayTodayPatient',
  );
  if (event && firstPatient) {
    return `${eventLabel}: ${getPreferredFullName(firstPatient)} ${timeDisplay}`;
  }

  return `${eventLabel} ${timeDisplay}`;
};
interface ScheduleWidgetComponentProps {
  currentTime: Date;
  providerId: string;
  popinEvent?: MinimalCalendarEventData | null;
}

const ScheduleWidgetComponent = ({
  currentTime,
  providerId,
  popinEvent,
}: ScheduleWidgetComponentProps) => {
  const styles = useStyles();
  const rootStore = useRootStore();
  const nearestMinute = roundToNearestMinutes(currentTime, { roundingMethod: 'floor' });
  const start = useMemo(() => nearestMinute, [nearestMinute.valueOf()]);

  useEffect(() => {
    async function loadProvider() {
      await rootStore.providers.load(providerId);
      await rootStore.providers.calendar.loadDateAndView(start, 'day');
    }
    loadProvider();
  }, [rootStore, providerId, start]);

  const {
    providers: { calendar, provider },
  } = rootStore;

  const handleCreateEvent = useCallback(
    options => {
      rootStore.events.createEvent(provider, options);
    },
    [provider],
  );

  const handleSaveEvent = (item, updates) => {
    rootStore.events.updateEventAndSave(item, updates);
  };

  const setSlotStyling = (slotTime: Date, availabilityInstances: EventInstance[]) => {
    const isOnTheHour = getMinutes(slotTime) === 0;
    const availabilitySlot = availabilityInstances.find(availability =>
      isSlotDuringEvent(slotTime, availability),
    );

    let backgroundColor;
    let borderTopColor;
    if (!availabilitySlot) {
      return {
        style: {
          borderTop: 'none',
          backgroundColor: 'transparent',
        },
      };
    } else if (!availabilitySlot.subType) {
      backgroundColor = 'white';
      borderTopColor = isOnTheHour ? calendarColors.OFF_WHITE : 'white';
    } else if (availabilitySlot.subType in AVAILABILITY_SUB_TYPE_ITEMS) {
      backgroundColor = AVAILABILITY_SUB_TYPE_ITEMS[availabilitySlot.subType].backgroundColor;
      borderTopColor = isOnTheHour
        ? calendarColors.OFF_WHITE
        : AVAILABILITY_SUB_TYPE_ITEMS[availabilitySlot.subType].backgroundColor;
    }
    return { style: { backgroundColor, borderTopColor } };
  };

  const eventPropGetter = (event: EventInstance) => {
    const style = getCalendarEventStyles(event);

    if (!event.start || !event.duration || !event.subType) {
      return { style };
    }

    const eventTime = useScheduledEventTiming(currentTime, event.start);
    const inProgress = isWithinInterval(currentTime, {
      start: event.start,
      end: addMinutes(event.start, event.duration),
    });

    style.borderColor = BoulderColors.Blue4;

    if (eventTime === EventTiming.Imminent) {
      style.backgroundColor = BoulderColors.Blue1;
      return { style };
    } else if (inProgress) {
      style.backgroundColor = Colors.NewLightRed;
      return { style };
    }

    if (BLOCKED_EVENT_SUB_TYPES.includes(event.subType as SubType)) {
      style.borderColor = EVENT_COLORS.BEIGE;
    }

    return { style };
  };

  const effectiveEventInstances = popinEvent
    ? [...calendar.eventInstances, popinEvent]
    : calendar.eventInstances;

  const timezone = provider?.timezone ?? localZone;

  return (
    <div className={styles.container}>
      <EventCalendar
        timezone={timezone}
        actionTooltip="Add event"
        calendar={calendar}
        className="striped-calendar"
        events={effectiveEventInstances}
        onCreate={handleCreateEvent}
        onSave={handleSaveEvent}
        title="Today's Schedule"
        showPatientColumn
        titleAccessor={event => getEventDisplayTitle(event, timezone)}
        eventPropGetter={eventPropGetter}
        tooltipAccessor={event => getEventToolTip(event, getEventDisplayTitle(event, timezone))}
        slotPropGetter={rawSlotTime => setSlotStyling(rawSlotTime, calendar.availableInstances)}
        showMainCalendarIcon
        showToolBar={false}
      />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    border: '1px solid',
    borderColor: BoulderColors.Gray2,
    backgroundColor: BoulderColors.White,
    width: '474px',
    height: '530px',
    padding: '24px 36px',
    display: 'flex',
    flexDirection: 'column',

    '& .rbc-event-content > div > svg': {
      color: `${BoulderColors.Gray5} !important`,
    },

    '& .rbc-time-gutter .rbc-time-slot': {
      backgroundColor: `${BoulderColors.White} !important`,
      borderTop: 'none',
    },

    '& .rbc-current-time-indicator': {
      // This needs to be !important because RBC will update
      // the style on the element directly if the contents of
      // the calendar change (though not when the time updates,
      // for some reason)
      height: '4.5px !important',
      backgroundColor: Colors.Coral,

      '&::before': {
        content: '""',
        display: 'block',
        width: 14,
        height: 14,
        backgroundColor: Colors.Coral,
        marginLeft: -7,
        marginTop: -5,
        borderRadius: 100,
      },
    },

    '& h1': {
      color: BoulderColors.Blue4,
      fontFamily: '"Nunito", "Nunito Sans"',
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '145%',
      letterSpacing: 0.201,
    },

    '& .MuiFab-root': {
      boxShadow: 'none',
    },
    '& > div': {
      height: '100%',
    },
    '& .rbc-calendar': {
      height: 'calc(100% - 51px) !important',
      flexShrink: 1,
    },
  },
});

export const ScheduleWidget = inject('rootStore')(observer(ScheduleWidgetComponent));
