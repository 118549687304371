import DateFnsAdapter from '@date-io/date-fns';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { init as initSentry } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { DocumentNode } from 'graphql';
import { configure as configureMobx } from 'mobx';
import { Provider } from 'mobx-react';
import { HistoryAdapter, RouterContext, browserHistory } from 'mobx-state-router';
import React from 'react';
import ReactGA from 'react-ga4';
import { SWRConfig } from 'swr';
import uuid from 'uuid/v4';
import * as Yup from 'yup';

import 'src/App.css';
import {
  ThemeProvider as NdsThemeProvider,
  GlobalStyles as NdsGlobalStyles,
} from 'src/common/ui/base';
import Main from 'src/components/app/main';
import FeatureFlagProvider from 'src/components/featureflags/featureFlagProvider';
import ErrorBoundary from 'src/components/general/ErrorBoundary';
import { ApolloClientContext } from 'src/data/ApolloClientContext';
import getFeatureFlagHeader from 'src/featureFlags/getFeatureFlagHeader';
import { createApolloClient } from 'src/shared/client/apolloClient';
import crudService from 'src/shared/services/crud';
import { getDeviceId } from 'src/shared/util/device';
import logger from 'src/shared/util/logger';
import RootStore from 'src/stores/root';
import { theme } from 'src/theme';
import { apiHost, apiSecure } from 'src/util';

// Set default validation error messages globally
Yup.setLocale({
  mixed: {
    required: 'required',
  },
});

// Recommended during mobx upgrade from 5->6
configureMobx({
  enforceActions: 'never',
});

/**
 * Retrieve the unique ID (UUID) of the current window's session. If no ID yet exists, one is
 * automatically created. This allows us to distinctly track activity in different
 * browsers/windows/tabs. The session ID survives page reloads and restores.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage|Window.sessionStorage}
 */
const getSessionId = () => {
  let id = window.sessionStorage.getItem('sessionId');
  if (!id) {
    id = uuid() as string;
    window.sessionStorage.setItem('sessionId', id);
  }
  return id;
};

const apolloClient = createApolloClient(
  String(apiHost),
  !!apiSecure,
  {
    'x-request-id': uuid,
    'x-device-id': getDeviceId,
    'x-session-id': getSessionId,
    'x-user-id': () => rootStore.auth.user?.id ?? '',
    'x-ff-overrides': getFeatureFlagHeader,
    'Apollo-Require-Preflight': () => 'true',
  },
  onErrorWS,
  onUnauthorized,
);

const rootStore = RootStore.create({}, { crudService: crudService(apolloClient), apolloClient });
const historyAdapter = new HistoryAdapter(rootStore.routerStore, browserHistory);
historyAdapter.observeRouterStateChanges();
if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

logger.setClient(apolloClient, 'staff');

async function onErrorWS(error) {
  if (error?.message === 'Forbidden') {
    await rootStore.auth.logout();
    rootStore.routerStore.goTo('login');
  }
}

function onUnauthorized() {
  if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
}

/**
 * @see {@link https://docs.sentry.io/platforms/javascript/guides/react/}
 * @see {@link https://docs.sentry.io/platforms/javascript/configuration/sampling/}
 */
initSentry({
  dsn: process.env.REACT_APP_SENTRY_DSN || '',
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [new BrowserTracing()],
  release: process.env.REACT_APP_COMMIT_SHA,
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 0.1,
});

/**
 * Allows loading data throughout the app with useSWR([query, variables]).
 *
 * As of SWR 2.x the fetcher function receives the key argument directly as
 * it is provided to `useSWR` -- previously it was automatically spread into
 * `query` and `variables` arguments.
 */
type FetcherKey = [DocumentNode, Record<string, unknown> | undefined] | DocumentNode;

async function defaultSWRFetcher(key: FetcherKey) {
  const [query, variables] = Array.isArray(key) ? key : [key, undefined];

  const ret = await apolloClient.query({
    query,
    variables,
  });

  return ret.data;
}

const App = () => (
  <ErrorBoundary>
    <Provider rootStore={rootStore}>
      <RouterContext.Provider value={rootStore.routerStore}>
        <FeatureFlagProvider>
          <MuiThemeProvider theme={theme}>
            <NdsThemeProvider>
              <NdsGlobalStyles />
              <MuiPickersUtilsProvider utils={DateFnsAdapter}>
                <SWRConfig value={{ fetcher: defaultSWRFetcher }}>
                  <ApolloClientContext.Provider value={{ apolloClient }}>
                    <Main />
                  </ApolloClientContext.Provider>
                </SWRConfig>
              </MuiPickersUtilsProvider>
            </NdsThemeProvider>
          </MuiThemeProvider>
        </FeatureFlagProvider>
      </RouterContext.Provider>
    </Provider>
  </ErrorBoundary>
);

export default App;
