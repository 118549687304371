import { createMuiTheme } from '@material-ui/core/styles';

import Colors from 'src/nightingale/Colors';
import { colors } from 'src/util/colors';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.BoulderBlue,
      contrastText: '#fff',
    },
    secondary: {
      main: Colors.BlueSpruce,
      dark: Colors.BoulderBlue,
      contrastText: '#fff',
    },
    warning: {
      main: colors.warning,
      contrastText: colors.taupe,
    },
  },
  typography: {
    fontFamily: '"Nunito Sans", "Open Sans", "Roboto", ui-sans-serif, system-ui, sans-serif',
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none',
        borderRadius: 0,
      },
      containedPrimary: {
        backgroundColor: Colors.BlueSpruce,
        '&:hover': {
          backgroundColor: Colors.BoulderBlue,
          boxShadow: 'none',
        },
        boxShadow: 'none',
        '&.Mui-disabled': {
          color: Colors.Gray4,
          backgroundColor: Colors.BlueSpruce,
        },
      },
      containedSecondary: {
        backgroundColor: Colors.Stillwater,
        '&:hover': {
          backgroundColor: Colors.BlueSpruce,
          boxShadow: 'none',
        },
        boxShadow: 'none',
        '&.Mui-disabled': {
          backgroundColor: Colors.Stillwater,
        },
      },
    },
  },
});
