import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import type { SvgIconComponent } from '@material-ui/icons';
import ColorizeIcon from '@material-ui/icons/Colorize';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import UpdateIcon from '@material-ui/icons/Update';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import parseISO from 'date-fns/parseISO';
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes';
import keyBy from 'lodash/fp/keyBy';
import mapValues from 'lodash/fp/mapValues';
import React, { useContext, useMemo, useState } from 'react';
import useSWR from 'swr';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import ErrorAlert from 'src/components/general/ErrorAlert';
import ConfirmDialog from 'src/components/pages/pageElements/confirmDialog';
import { useClinicActions } from 'src/dropInClinic/ClinicActions.hook';
import { requestTypeMap } from 'src/dropInClinic/RequestType';
import RequiresSchedulingWarning from 'src/dropInClinic/RequiresSchedulingWarning';
import { CAN_PATIENT_BE_SCHEDULED } from 'src/dropInClinic/queries/canPatientBeScheduled.gql';
import { GET_PATIENT_DROP_IN_HOURS } from 'src/dropInClinic/queries/getPatientDropInHours.gql';
import { CLINIC_ACTIONS_OVERRIDE } from 'src/featureFlags/currentFlags';
import {
  CanPatientBeScheduledForClinicQuery,
  DropInClinicPatientHoursQuery,
} from 'src/generated/gql/graphql';
import Colors from 'src/nightingale/Colors';
import { PageTitle } from 'src/nightingale/components/common/PageTitle/PageTitle';

type RequestType = keyof typeof requestTypeMap;

const iconMap: Record<
  Extract<
    RequestType,
    'FOLLOW_UP' | 'INTAKE' | 'OFT' | 'PEER_VISIT' | 'REENGAGEMENT' | 'CASE_MANAGER_VISIT'
  >,
  SvgIconComponent
> = {
  FOLLOW_UP: UpdateIcon,
  INTAKE: GroupAddIcon,
  OFT: ColorizeIcon,
  PEER_VISIT: SupervisedUserCircleIcon,
  REENGAGEMENT: RotateRightIcon,
  CASE_MANAGER_VISIT: SupervisorAccount,
};

const HoursDisplay = ({ hoursData }) => {
  if (!hoursData) return <></>;

  return (
    <div
      style={{
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        color: Colors.Gray7,
        fontFamily: '"Nunito", "Nunito Sans"',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 1.1,
      }}
    >
      Pop-In Clinic hours:
      {Object.entries(hoursData)
        .filter(([, queueHours]) => Array.isArray(queueHours) && queueHours.length)
        .map(([queueType, queueHours]) => (
          <div
            key={queueType}
            style={{
              margin: 8,
            }}
          >
            {queueType}
            {(queueHours as string[]).map(hours => (
              <div key={hours}>
                {'\n \u2022 '}
                {hours}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export const ClinicActions = ({ patientId }: { patientId: string }) => {
  const classes = useStyles();

  const [exceedingQueue, setExceedingQueue] = useState<RequestType | null>(null);
  const { state, createRequest } = useClinicActions(patientId);
  const isLoading = state?.state === 'loading';
  const loadingRequestType = isLoading ? state?.requestType : null;
  const spinner = <CircularProgress size={20} />;

  const { data: hoursData } = useSWR<DropInClinicPatientHoursQuery>([
    GET_PATIENT_DROP_IN_HOURS,
    { patientId },
  ]);
  const { data: canBeScheduledData } = useSWR<CanPatientBeScheduledForClinicQuery>([
    CAN_PATIENT_BE_SCHEDULED,
    { patientId, requestTypes: Object.keys(requestTypeMap) },
  ]);

  const canBeScheduledMap = useMemo(() => {
    const queryResultMap = keyBy(
      x => x.requestType,
      canBeScheduledData?.staff_canPatientBeScheduledForClinic ?? [],
    );
    return mapValues(
      ({
        canBeScheduled,
        canBeScheduledToday,
        availabilityWindowEnd,
        willUnscheduleAnotherPatient,
      }) => ({
        canBeScheduled,
        canBeScheduledToday,
        willUnscheduleAnotherPatient,
        availabilityWindowEnd: parseISO(availabilityWindowEnd),
      }),
      queryResultMap,
    );
  }, [canBeScheduledData]);

  const canOverrideQueue = useContext(FeatureFlagContext)[CLINIC_ACTIONS_OVERRIDE];

  const hasSchedulingComplication = (requestType: RequestType) =>
    !canBeScheduledMap[requestType]?.canBeScheduled ||
    canBeScheduledMap[requestType].willUnscheduleAnotherPatient;

  const attemptCreateRequestAutopilot = async (requestType: RequestType) => {
    if (hasSchedulingComplication(requestType)) {
      setExceedingQueue(requestType);
    } else {
      await createRequest(requestType);
    }
  };

  const QueueButton = ({ requestType }: { requestType: RequestType }) => {
    const Icon = iconMap[requestType];

    return (
      <Button
        color="secondary"
        variant="contained"
        startIcon={isLoading && loadingRequestType === requestType ? spinner : <Icon />}
        disabled={isLoading || !canOverrideQueue}
        onClick={() => attemptCreateRequestAutopilot(requestType)}
        className={hasSchedulingComplication(requestType) ? 'will-not-be-seen' : 'will-be-seen'}
        style={{
          width: '400px',
          marginRight: '10px',
          backgroundColor: hasSchedulingComplication(requestType) ? Colors.Coral : '',
        }}
      >
        {requestTypeMap[requestType]}
      </Button>
    );
  };

  const ScheduleEstimate = ({ requestType }: { requestType: RequestType }) => {
    const {
      canBeScheduled,
      canBeScheduledToday,
      willUnscheduleAnotherPatient,
      availabilityWindowEnd,
    } = canBeScheduledMap[requestType] || {};

    // This is an intentional loose equality to catch both null and undefined
    if (canBeScheduled == null) {
      return null;
    }

    const minutes = differenceInMinutes(
      availabilityWindowEnd,
      roundToNearestMinutes(new Date(), { roundingMethod: 'ceil' }),
    );

    let scheduleText = 'We estimate that this patient WILL NOT be seen TODAY';
    if (willUnscheduleAnotherPatient)
      scheduleText = `We estimate that this patient will be seen in the next ${minutes}, but this patient WILL UNSCHEDULE another patient`;
    else if (canBeScheduled)
      scheduleText = `We estimate that this patient WILL be seen in the next ${minutes} minutes`;
    else if (canBeScheduledToday)
      scheduleText = `We estimate that this patient WILL NOT be seen in the next ${minutes} minutes`;

    return (
      <div className={hasSchedulingComplication(requestType) ? 'will-not-be-seen' : 'will-be-seen'}>
        {scheduleText}
      </div>
    );
  };

  return (
    <div style={{ maxWidth: 950 }}>
      <PageTitle>Clinic Actions</PageTitle>
      <RequiresSchedulingWarning patientId={patientId} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        {Object.keys(requestTypeMap).map((requestType: RequestType) => {
          return (
            <div className={classes.requestTypeRow}>
              <QueueButton requestType={requestType} />
              <div className={classes.capacityColumn}>
                <ScheduleEstimate requestType={requestType} />
              </div>
            </div>
          );
        })}
        {state?.state === 'error' && (
          <ErrorAlert
            message={state.errors?.join(', ') || 'Error adding patient to queue'}
            title="Error creating Pop-In Clinic request"
          />
        )}
        {state?.state === 'no_availability' && (
          <Alert severity="error">No availability for patient</Alert>
        )}
        {state?.state === 'success' && (
          <>
            <Alert severity="success">Patient added to queue</Alert>
            {state.warning && <Alert severity="info">{state.warning}</Alert>}
          </>
        )}
        <HoursDisplay hoursData={hoursData?.getPatientDropInClinicHours} />
      </div>
      {exceedingQueue && (
        <ConfirmDialog
          isDestructive
          onSubmit={async () => {
            await createRequest(exceedingQueue);
            setExceedingQueue(null);
          }}
          onCancel={() => setExceedingQueue(null)}
          submitLabel="Add patient"
        >
          Are you sure that you would like to exceed available capacity
        </ConfirmDialog>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  requestTypeRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  capacityColumn: {
    display: 'flex',
    flexDirection: 'column',

    '& .will-not-be-seen': {
      color: Colors.Coral,
    },
  },
}));
