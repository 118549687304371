import Button from '@material-ui/core/Button';
import type { ButtonProps } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import React from 'react';

import Colors from 'src/nightingale/Colors';

export const PrimaryButton: React.FC<
  Pick<ButtonProps, 'className' | 'children' | 'disabled' | 'onClick' | 'startIcon' | 'type'>
> = ({ children, className, disabled, onClick, startIcon, type }) => {
  const styles = useButtonStyles();
  return (
    <Button
      classes={{ root: classnames(styles.iconButton, className) }}
      data-testid="action-button"
      disabled={disabled}
      onClick={onClick}
      startIcon={startIcon}
      type={type}
    >
      {children}
    </Button>
  );
};

const useButtonStyles = makeStyles({
  iconButton: {
    alignItems: 'center',
    backgroundColor: Colors.Stillwater,
    border: 'none',
    borderRadius: 0,
    color: Colors.White,
    cursor: 'pointer',
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    letterSpacing: 1.12,
    margin: 0,
    padding: '8px 16px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: Colors.BlueSpruce,
    },
  },
});
