import { makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';

/**
 * For errors that the user can fix without needing to file a help desk ticket (e.g. missing
 * chart information).
 */
const UserFixableErrorAlert: React.FC<{
  errorMessage: string;
}> = ({ errorMessage }) => {
  const styles = useStyles();

  return (
    <Alert
      classes={{ root: styles.container, message: styles.message }}
      data-testid="error-container"
      severity="error"
    >
      <pre className={styles.code}>
        <code>{errorMessage}</code>
      </pre>
    </Alert>
  );
};

const useStyles = makeStyles({
  container: {
    fontFamily: '"Nunito", "Nunito Sans"',
  },
  title: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
  },
  message: {
    fontSize: 16,
  },
  code: {
    whiteSpace: 'pre-wrap',
    marginTop: 0,
  },
});

export default UserFixableErrorAlert;
