import { makeStyles } from '@material-ui/core';
import debounce from 'lodash/debounce';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import React, { useCallback, useEffect, useState, useContext } from 'react';

import ErrorAlert from 'src/components/general/ErrorAlert';
import Colors from 'src/nightingale/Colors';
import { ChartContextProvider } from 'src/nightingale/components/ChartContext/ChartContext';
import { ChartOverview } from 'src/nightingale/components/ChartOverview/ChartOverview';
import { ChartOverviewSection } from 'src/nightingale/components/ChartOverview/ChartOverviewSection';
import { ChartSearch } from 'src/nightingale/components/ChartSearch/ChartSearch';
import { SearchTermContext } from 'src/nightingale/components/ChartSearch/SearchTermContext';
import { filterChartSections } from 'src/nightingale/components/ChartSearch/filterChartSections';
import { ChartUpdateContext } from 'src/nightingale/components/ChartUpdateContext/ChartUpdateContext';
import { ConditionalContextProvider } from 'src/nightingale/components/ConditionalContext/ConditionalContext';
import { RequiredFieldsWarning } from 'src/nightingale/components/RequiredFieldsWarning/RequiredFieldsWarning';
import ChartLoadingSpinner from 'src/nightingale/components/common/ChartLoadingSpinner/ChartLoadingSpinner';
import { PageColumn } from 'src/nightingale/components/common/PageColumn/PageColumn';
import { getConditions } from 'src/nightingale/conditionals';
import { useSWRChartOverview } from 'src/nightingale/data/useSWRChartOverview';
import { RequiredChartPropertiesContextProvider } from 'src/nightingale/requiredChartProperties/RequiredChartPropertiesContext';
import { AnyChartProperty } from 'src/nightingale/types/types';

/**
 * Styles
 */
const useStyles = makeStyles({
  container: {
    backgroundColor: Colors.ChartGray,
    height: '100%',
    minHeight: '100vh',
    padding: '36px 50px',
  },
  content: {
    maxWidth: 600,
  },
  noResultsMessage: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontStyle: 'italic',
    fontSize: 16,
    lineHeight: '23.2px',
  },
});

/**
 * Component
 * @todo 🚧 we should move all the logic and styling of this component into
 * `src/nightingale/components/ChartOverview` and get rid of this `pages`
 * folder.
 */
const PatientOverview: React.FC<{
  showSearch?: boolean;
  patientId: string;
}> = ({ patientId, showSearch = true }) => {
  const styles = useStyles();

  const { data, error, mutate } = useSWRChartOverview(patientId);

  const { lastUpdate } = useContext(ChartUpdateContext);

  const missingRequiredFieldDisplayNames = getMissingRequiredFieldDisplayNames(
    data?.missingRequiredFields,
    data?.propertiesByName,
  );

  useEffect(() => {
    mutate();
  }, [lastUpdate]);

  const [searchTerm, setSearchTerm] = useState('');
  const searchCallback = useCallback(
    debounce(
      text => {
        setSearchTerm(text);
      },
      250,
      { leading: false, trailing: true },
    ),
    [],
  );

  const chartSections = filterChartSections(data?.overview?.sections ?? [], searchTerm);

  const onChangeSearch = text => {
    searchCallback.cancel();
    // when searching, delay render until 250ms after typing stops
    // when clearing the search, render immediately
    if (!text) setSearchTerm('');
    else searchCallback(text);
  };

  return (
    <SearchTermContext.Provider value={searchTerm}>
      <PageColumn>
        {!data && !error ? <ChartLoadingSpinner message="Loading Overview..." /> : null}
        {error ? <ErrorAlert message="Error loading overview." error={error} /> : null}
        {data ? (
          <ConditionalContextProvider
            conditionalContext={data.context}
            allConditions={flatMap(data.overview.sections, getConditions)}
          >
            <ChartOverview>
              {showSearch && <ChartSearch onChange={onChangeSearch} />}

              {missingRequiredFieldDisplayNames.length > 0 ? (
                <RequiredFieldsWarning items={missingRequiredFieldDisplayNames} />
              ) : null}

              <ChartContextProvider patientId={patientId}>
                {chartSections.map(overviewSection => (
                  <RequiredChartPropertiesContextProvider
                    requiredChartProperties={overviewSection.requiredChartPropertiesConfig}
                  >
                    <ChartOverviewSection key={overviewSection.name} {...overviewSection} />
                  </RequiredChartPropertiesContextProvider>
                ))}
              </ChartContextProvider>
              {!chartSections.find(({ hidden }) => !hidden) && (
                <div className={styles.noResultsMessage}>No results found.</div>
              )}
            </ChartOverview>
          </ConditionalContextProvider>
        ) : null}
      </PageColumn>
    </SearchTermContext.Provider>
  );
};

/**
 * Convert a list of names of missing required chart properties to more human-friendly display
 * names, which in this case means getting the label associated with each chart property.
 */
function getMissingRequiredFieldDisplayNames(
  missingRequiredFields: string[] | undefined,
  propertiesByName: Record<string, AnyChartProperty> | undefined,
): string[] {
  if (!missingRequiredFields || !propertiesByName) {
    return [];
  }

  return missingRequiredFields.map(propName => getLabelFromPropName(propName, propertiesByName));
}

function getLabelFromPropName(
  propName: string,
  properties: Record<string, AnyChartProperty>,
): string {
  if (propName.indexOf('.') > -1) {
    const [parentPropName, childPropName] = propName.split('.');
    const parentProp: AnyChartProperty = get(properties, parentPropName);

    if (!parentProp || !('properties' in parentProp)) return propName;

    const childProp = parentProp.properties.find(prop => prop.name === childPropName);

    if (!childProp) return propName;

    return `${parentProp.label || parentProp.name} (${childProp.label || childProp.name})`;
  }

  const prop = get(properties, propName);
  return prop?.label || prop?.name || propName;
}

export default PatientOverview;
