import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneAll from '@material-ui/icons/DoneAll';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { PrimaryButton } from 'src/components/general/PrimaryButton';
import { SecondaryButton } from 'src/components/general/SecondaryButton';
import { useSWRPatientShippingAddress } from 'src/labs/useSWRPatientShippingAddress';
import Colors from 'src/nightingale/Colors';
import LazyLoadingSpinner from 'src/nightingale/components/common/LazyLoadingSpinner/LazyLoadingSpinner';

export const OrderNewKitDialog: React.FC<{
  isLoadingNewOrder: boolean;
  isOpen: boolean;
  onPlaceOrder: () => void;
  overviewAddressUrl: string;
  patientId: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isLoadingNewOrder, isOpen, onPlaceOrder, overviewAddressUrl, patientId, setShowModal }) => {
  const styles = useStyles();
  const { data, isLoading, error } = useSWRPatientShippingAddress(patientId);

  return (
    <Dialog
      data-testid="history-modal"
      disablePortal
      open={isOpen}
      maxWidth="sm"
      fullWidth
      onClose={() => setShowModal(false)}
    >
      <DialogTitle classes={{ root: styles.title }}>
        Confirm patient’s shipping address and order
      </DialogTitle>

      <DialogContent classes={{ root: styles.content }}>
        <p className={styles.intro}>
          The kit will be shipped to the address on file for the patient. Please confirm the address
          and click “Order” to proceed.
        </p>

        {data?.houselessStatus ? (
          <p className={styles.houselessWarning}>
            This patient is currently experiencing houselessness.
          </p>
        ) : null}

        <div className={styles.addressInfo}>
          {isLoading ? (
            <div data-testid="address-loading-spinner">
              <LazyLoadingSpinner />
            </div>
          ) : null}
          {error ? (
            <p className={styles.addressError}>Failed to load patient shipping address.</p>
          ) : null}
          {data?.address ? (
            <>
              {`${data.address.firstName} ${data.address.lastName}`}
              <br />
              {data.address.address1}
              <br />
              {data.address.address2 ? (
                <>
                  {data.address.address2}
                  <br />
                </>
              ) : null}
              {`${data.address.city}, ${data.address.state} ${data.address.zip}`}
            </>
          ) : (
            <p className={styles.addressError}>No valid address found for this patient.</p>
          )}
        </div>

        <p className={styles.warning}>
          If the shipment address is incorrect, please update it directly{' '}
          <a href={overviewAddressUrl}>in the patient’s</a> chart first and return here to order.
        </p>

        {isLoadingNewOrder && (
          <div className={styles.isLoadingNewOrder}>
            <LazyLoadingSpinner />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <SecondaryButton disabled={isLoadingNewOrder} onClick={() => setShowModal(false)}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={isLoadingNewOrder || isLoading || !!error || !data?.address}
          onClick={onPlaceOrder}
          startIcon={<DoneAll />}
        >
          Order
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    '& .MuiTypography-root': {
      color: Colors.Gray5,
      fontFamily: 'Tenor Sans',
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.45,
      letterSpacing: 0.2,
    },
  },
  content: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    lineHeight: 1.45,
    position: 'relative',

    '& a': {
      color: 'inherit',
      textDecoration: 'underline',
    },
  },
  intro: {
    color: Colors.BlueSpruce,
    fontWeight: 600,
    marginTop: 0,
  },
  houselessWarning: {
    color: Colors.NewRed,
    fontWeight: 600,
  },
  addressError: {
    color: Colors.NewRed,
    fontWeight: 600,
    marginBottom: 0,
    marginTop: 0,
  },
  addressInfo: {
    backgroundColor: '#F0F6F7',
    padding: 16,
  },
  warning: {
    color: Colors.Gray7,
    fontStyle: 'italic',
    fontWeight: 400,
  },
  isLoadingNewOrder: {
    alignItems: 'center',
    background: 'rgba(255, 255, 255, 0.5)',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});
