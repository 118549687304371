import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { format, isValid } from 'date-fns';
import React, { useState } from 'react';

import { PrimaryButton } from 'src/components/general/PrimaryButton';
import { SecondaryButton } from 'src/components/general/SecondaryButton';
import RouteLink from 'src/components/general/routeLink';
import Colors from 'src/nightingale/Colors';

export const PatientExportDialog: React.VFC<{
  isOpen: boolean;
  close: () => void;
  patientId: string;
}> = ({ isOpen, close, patientId }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const styles = useStyles();

  return (
    <Dialog disablePortal open={isOpen} maxWidth="sm" onClose={close}>
      <DialogTitle classes={{ root: styles.title }}>Chart Export</DialogTitle>
      <DialogContent classes={{ root: styles.content }}>
        <p className={styles.intro}>
          Select a date range of visits to export, or leave blank to include all visits.
        </p>
        <div>
          <KeyboardDatePicker
            label="Start date (optional)"
            onChange={value => setStartDate(value)}
            value={startDate}
            format="MM/dd/yyyy"
            autoOk
            invalidDateMessage="Date must be formatted like: 01/31/2019"
          />
        </div>
        <div>
          <KeyboardDatePicker
            label="End date (optional)"
            onChange={value => setEndDate(value)}
            value={endDate}
            required={false}
            format="MM/dd/yyyy"
            autoOk
            invalidDateMessage="Date must be formatted like: 01/31/2019"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={() => close()}>Close</SecondaryButton>
        <RouteLink
          className={styles.buttonLink}
          route="patientExport"
          routeParams={[
            { id: patientId },
            {
              ...(startDate &&
                isValid(startDate) && { startDate: format(startDate, 'yyyy-MM-dd') }),
              ...(endDate && isValid(endDate) && { endDate: format(endDate, 'yyyy-MM-dd') }),
            },
          ]}
          newWindow
        >
          <PrimaryButton startIcon={<OpenInNewIcon />}>View Export</PrimaryButton>
        </RouteLink>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  title: {
    '& .MuiTypography-root': {
      color: Colors.Gray5,
      fontFamily: 'Tenor Sans',
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 1.45,
      letterSpacing: 0.2,
    },
  },
  content: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    lineHeight: 1.45,
    position: 'relative',
  },
  intro: {
    color: Colors.BlueSpruce,
    fontWeight: 600,
    marginTop: 0,
  },
  buttonLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
});
